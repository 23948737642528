import HTTP from "./http-common";

const SAUDI_ARABIA_DIAL_CODE = "966";

class BackendError extends Error {
  constructor(message) {
    super(message || "Unkown Error");

    this.name = "BackendError";
    this.message = message;
  }
}

function getHeaders(language) {
  return {
    "Content-Type": "application/json",
    "X-Ego-Device": "web",
    "X-Ego-Language": language
  };
}

function handleRequestError(err) {
  const res = err.response;

  if (!res) {
    // eslint-disable-next-line no-console
    console.error(err);

    // eslint-disable-next-line no-param-reassign
    err.message = "Unknown Internal Error";

    throw err;
  }

  throw new BackendError(res.data.message);
}

export default {
  // eslint-disable-next-line consistent-return
  async login(language, role, phone, password, dialCode) {
    try {
      const data = {
        device: "web",
        role,
        dialCode: dialCode,
        phone,
        password
      };

      const headers = getHeaders(language);

      const res = await HTTP.post("/sessions2", data, { headers });

      const { data: session, user } = res.data;

      // @hack: can't change because it can affect other parts of the code
      HTTP.defaults.headers["X-Ego-Access-Token"] = session.accessToken;

      return { session, user };
    } catch (err) {
      handleRequestError(err);
    }
  },
  async loginOtp(language, role, phone, otp, dialCode) {
    try {
      const data = {
        device: "web",
        role,
        dialCode: dialCode,
        phone,
        otp
      };

      const headers = getHeaders(language);

      const res = await HTTP.post("/sessions2/login-otp", data, { headers });

      const { data: session, user } = res.data;

      // @hack: can't change because it can affect other parts of the code
      HTTP.defaults.headers["X-Ego-Access-Token"] = session.accessToken;

      return { session, user };
    } catch (err) {
      handleRequestError(err);
    }
  },
  // eslint-disable-next-line consistent-return
  async registerClient(
    language,
    {
      firstName,
      lastName,
      phone,
      email,
      password,

      referredBy,
      dialCode
    }
  ) {
    try {
      const data = {
        client: {
          dialCode: dialCode,

          firstName,
          lastName,
          phone,
          email,
          password,
          passwordConfirmation: password,

          referredBy
        }
      };

      const headers = getHeaders(language);

      const response = await HTTP.post("/clients", data, { headers });

      HTTP.defaults.headers["X-Access-Token"] = "";
      HTTP.defaults.headers["X-Ego-Access-Token"] = "";
      HTTP.defaults.headers["X-Ego-Phone-Access-Token"] = "";

      localStorage.removeItem("accessToken");

      return response.data.data;
    } catch (err) {
      handleRequestError(err);
    }
  },
  // eslint-disable-next-line consistent-return
  async resetPassword(language, role, password, passwordConfirmation) {
    try {
      const data = {
        password,
        passwordConfirmation
      };

      const headers = getHeaders(language);

      const url = `/${role}s/reset-password`;
      await HTTP.put(url, data, { headers });
    } catch (err) {
      handleRequestError(err);
    }
  },
  // eslint-disable-next-line consistent-return
  async resetPasswordByLink(token, password, language, role = "driver") {
    try {
      const data = {
        password,
        token
      };

      const headers = getHeaders(language);

      const url = `/${role}s/reset-password-by-link`;
      await HTTP.put(url, data, { headers });
    } catch (err) {
      handleRequestError(err);
    }
  },
  // eslint-disable-next-line consistent-return
  async sendOtp(language, role, operation, phone, dialCode) {
    try {
      const data = {
        role,
        operation,
        dialCode: dialCode,
        phone
      };

      const headers = getHeaders(language);

      const url = "/phone-sessions/send-otp";
      const res = await HTTP.post(url, data, { headers });

      const { expiry, phoneRegistered } = res.data;

      return { expiry, phoneRegistered };
    } catch (err) {
      handleRequestError(err);
    }
  },
  // eslint-disable-next-line consistent-return
  async verifyOtp(language, role, operation, phone, otp, dialCode) {
    try {
      const data = {
        role,
        operation,
        dialCode: dialCode,
        phone,
        otp
      };

      const headers = getHeaders(language);

      const url = "/phone-sessions/verify-otp";
      const res = await HTTP.post(url, data, { headers });
      const response = res.data.data;

      // @hack: can't change because it can affect other parts of the code
      HTTP.defaults.headers["X-Phone-Access-Token"] = response.accessToken;

      return response;
    } catch (err) {
      handleRequestError(err);
    }
  }
};
