<template>
  <v-container fluid class="footer px-0">
    <!--<div class="oval-1"></div>-->
    <!--<div class="oval-2"></div>-->
    <!--<div class="oval-3"></div>-->

    <v-container class="mt-4">
      <v-layout row wrap>
        <v-flex md4 xs12>
          <img src="../../static/footer/logo.svg" alt="" />
        </v-flex>
        <v-flex md1 xs12 offset-md1 class="align-center my-3">
          <a
            href="https://twitter.com/Ego_Now"
            target="_blank"
            style="text-decoration: none"
          >
            <div class="social-container">
              <i class="flaticon flaticon-twitter-social-outlined-logo"></i>
            </div>
          </a>
        </v-flex>

        <v-flex md2 xs6 offset-md2 class="align-center">
          <a
            :href="androidAppUrl"
            target="_blank"
            class="social-media-btn android-client"
          >
            <img
              class="icon"
              src="../../static/social-media/google-play.svg"
              alt=""
            />
            <div class="btn-content">
              <span class="btn-top">{{ $t("get it on") }}</span
              ><span class="btn-bottom">{{ $t("google play") }}</span>
            </div>
          </a>
        </v-flex>
        <v-flex md2 xs6 class="align-center">
          <a
            :href="iosAppUrl"
            target="_blank"
            class="social-media-btn ios-client"
          >
            <img
              class="icon"
              src="../../static/social-media/apple.svg"
              alt=""
            />
            <div class="btn-content">
              <span class="btn-top">{{ $t("available on") }}</span
              ><span class="btn-bottom">{{ $t("app store") }}</span>
            </div>
          </a>
        </v-flex>
      </v-layout>
      <div class="divider"></div>
      <v-layout row wrap class="my-5 text-xs-center">
        <v-flex xs12 md5>
          <p class="text">
            {{ $t("footer paragraph") }}
          </p>
        </v-flex>
        <v-flex xs12 md4>
          <router-link class="footer-link" :to="`/${lang}/privacy`">{{
            $t("privacyTitle")
          }}</router-link>
          <br />
          <router-link class="footer-link" :to="`/${lang}/terms`">{{
            $t("termsTitle")
          }}</router-link>
        </v-flex>
        <v-flex xs12 md3>
          <a
            href="https://www.youtube.com/watch?v=NZ8rj4f1p0g"
            target="_blank"
            class="footer-link"
          >
            {{ $t("howToUseDriver") }}
          </a>
          <br />
          <a href="mailto:care@ego-now.com" target="_blank" class="footer-link">
            care@ego-now.com
          </a>
        </v-flex>
      </v-layout>
    </v-container>
    <!--<img src="../../static/footer/path-bg.png"-->
    <!--srcset="../../static/footer/path-bg@2x.png 2x,-->
    <!--../../static/footer/path-bg@3x.png 3x"-->
    <!--class="path-bg">-->
  </v-container>
</template>

<script>
import "../../static/font/flaticon.css";
import config from "../config";

export default {
  name: "Footer",
  computed: {
    lang() {
      return localStorage.lang || this.$route.path.substring(1, 3);
    }
  },
  data() {
    return {
      howToDialog: false,

      androidAppUrl: config.androidAppUrl("client"),
      iosAppUrl: config.iosAppUrl("client")
    };
  },
  watch: {
    howToDialog(val) {
      if (val === false) {
        // eslint-disable-next-line no-useless-concat
        this.$refs.yVideo.contentWindow.postMessage(
          '{"event":"command","func":"' + "pauseVideo" + '","args":""}',
          "*"
        );
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.footer {
  position: relative;
  background-image: linear-gradient(42deg, #00caf1, #00a0e2 88%, #009ae0);
  clip-path: polygon(
    0 0,
    100% 0,
    100% 94%,
    94% 90%,
    68% 98%,
    20% 91%,
    13% 95%,
    8% 92%,
    4% 100%,
    0 94%
  );
  /*box-shadow: 0 10px 70px 0 black;*/
  &.rtl {
    direction: rtl;
  }
}
.oval-1 {
  width: 418px;
  height: 416px;
  border-radius: 50%;
  opacity: 0.15;
  background-image: radial-gradient(circle at 50% 80%, #9cecfb, #0052d4);
}
.oval-2 {
  width: 535px;
  height: 254px;
  border-radius: 50%;
  opacity: 0.09;
  background-image: radial-gradient(circle at 50% 80%, #9cecfb, #0052d4);
}
.oval-3 {
  width: 580px;
  height: 446px;
  border-radius: 50%;
  opacity: 0.22;
  background-image: radial-gradient(circle at 50% 0, #9cecfb, #0052d4);
}
.path-bg {
  position: absolute;
  top: 130px;
  /*width: 1440px;*/
  height: 327px;
  object-fit: cover;
  mix-blend-mode: overlay;
  width: 100vw;
}

.social-media-btn {
  text-decoration: none;
  min-width: 95%;
  display: inline-block;
  position: relative;
  border-radius: 6px;
  background-color: #ffffff;
  padding: 8px 30px 8px 50px;
  box-shadow: 0 7px 20px 0 rgba(0, 0, 0, 0.19);
  font-family: Roboto;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0.4px;
  color: #000;
  transition: all 0.2s;
  @media screen and (max-width: 450px) {
    font-size: 12px;
    padding-left: 40px;
    padding-right: 15px;
  }
  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 14px 20px 0 rgba(0, 0, 0, 0.19);
  }
  &:active {
    transform: translateY(-1px);
    box-shadow: 0 7px 20px 0 rgba(0, 0, 0, 0.19);
  }
  .icon {
    width: 25px;
    position: absolute;
    left: 15px;
    top: 15px;
    @media screen and (max-width: 450px) {
      top: 10px;
      left: 10px;
    }
  }
  .btn-content {
    display: flex;
    flex-direction: column;
    text-align: left;
    .btn-top {
      font-size: 10px;
    }
    .btn-bottom {
    }
  }
}

.align-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.social-container {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 3px solid #fff;
  display: flex;
  transition: all 0.5s;
  cursor: pointer;
  margin-right: auto;
  margin-left: auto;

  .flaticon {
    color: #fff;
    margin-left: -13px;
    margin-top: 3px;
  }
  &:hover {
    transform: scale(1.5) rotate(360deg);
    background-color: #fff;
    .flaticon {
      color: #00a0e2;
    }
  }
}
.divider {
  width: 1163px;
  height: 3px;
  margin-top: 13px;
  background-color: rgba(255, 255, 255, 0.19);
}

p.text {
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
}

.footer-link {
  display: inline-block;
  text-decoration: none;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.5px;
  color: #ffffff;
  transition: all 0.2s;
  cursor: pointer;
  margin-bottom: 15px;
  z-index: 99;
  &:hover {
    transform: translateY(-3px) scale(1.15);
  }
  &:active {
    transform: translateY(-1px);
  }
}
</style>
