export default {
  referredBy(newValue = null) {
    const KEY = 'referredBy';

    if (newValue) {
      sessionStorage.setItem(KEY, newValue);
      return '';
    }

    return sessionStorage.getItem(KEY) || '';
  },
};
