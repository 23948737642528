<template>
  <transition name="fade" mode="out-in">
    <v-container
      @keypress.capture.enter="next"
      key="steps"
      v-if="this.current !== 2"
      :class="{ rtl: lang === 'ar' }"
      class="steps"
    >
      <v-dialog v-model="viewImageDialog" max-width="815px">
        <v-btn
          flat
          icon
          absolute
          fab
          @click="viewImageDialog = false"
          class="close-icon"
        >
          <v-icon>close</v-icon>
        </v-btn>
        <div>
          <v-tooltip top>
            <img
              slot="activator"
              :src="viewImageSrc"
              alt=""
              style="max-width: 800px; width: 100%; height: 100%"
            />
            <span>{{ viewImageTip || "Image Name not available " }}</span>
          </v-tooltip>
        </div>
      </v-dialog>
      <v-layout class="header">
        <div class="header-line"></div>
        <h1 class="header-title">
          {{ $t("create account in 3 steps") }}
        </h1>
        <div class="header-line"></div>
      </v-layout>
      <v-layout justify-center>
        <v-flex md1 class="step-head step-head-active">
          <div class="guide"></div>
        </v-flex>
        <v-flex
          md4
          class="step-line"
          :class="{ 'step-line-active': current > 0 }"
        >
          <div class="line"></div>
        </v-flex>
        <v-flex
          md1
          class="step-head"
          :class="{ 'step-head-active': current >= 1 }"
        >
          <div class="guide"></div>
        </v-flex>
      </v-layout>
      <v-layout class="step-title">
        <v-flex md1 class="step-title-active">
          {{ $t("personal info") }}
        </v-flex>
        <v-flex
          md1
          offset-md4
          :class="{ 'step-title-active': current > 0 }"
          v-if="lang === 'en'"
        >
          {{ $t("attachments") }}
        </v-flex>
        <v-flex md4 v-if="lang === 'ar'"></v-flex>
        <v-flex
          md1
          :class="{ 'step-title-active': current > 0 }"
          v-if="lang === 'ar'"
        >
          {{ $t("attachments") }}
        </v-flex>
      </v-layout>
      <transition name="slide2" mode="out-in">
        <v-layout
          key="1"
          v-if="current === 0"
          wrap
          style="margin-top: 70px"
          justify-center
        >
          <v-form
            style="display: flex; flex-wrap: wrap"
            v-model="validForm1"
            ref="form1"
            lazy-validation
          >
            <v-flex md6 xs12>
              <v-text-field
                :rules="[rules.required, rules.letters]"
                :label="$t('first name')"
                box
                v-model="firstName"
                @change="saveToLocalStorage('firstName', $event)"
                required
              ></v-text-field>
            </v-flex>
            <v-flex xs12 md6>
              <v-text-field
                :rules="[rules.required, rules.letters]"
                box
                @change="saveToLocalStorage('lastName', $event)"
                :label="$t('last name')"
                v-model="lastName"
                required
              ></v-text-field>
            </v-flex>
            <v-flex md6 xs12>
              <v-text-field
                :rules="[rules.required, rules.email]"
                box
                @change="saveToLocalStorage('email', $event)"
                :label="$t('email')"
                v-model="email"
                required
              ></v-text-field>
            </v-flex>
            <v-flex md6 xs12 style="position: relative">
              <v-text-field
                ref="password"
                :type="show1 ? 'text' : 'password'"
                :rules="[
                  rules.required,
                  value => value.length > 5 || $t('passwordLetters')
                ]"
                box
                :label="$t('password')"
                v-model="password"
                required
              ></v-text-field>
              <v-icon
                class="icon-1"
                :class="{ 'icon-rtl': lang === 'ar' }"
                @click="show1 = !show1"
              >
                {{ show1 ? "visibility_off" : "visibility" }}
              </v-icon>
            </v-flex>
            <v-flex md6 xs12 style="position: relative">
              <v-text-field
                ref="passwordConfirmation"
                :type="show2 ? 'text' : 'password'"
                :rules="[
                  rules.required,
                  value => value === password || $t('passwordMatch')
                ]"
                box
                :label="$t('confirm password')"
                v-model="passwordConfirmation"
                required
              ></v-text-field>
              <v-icon
                class="icon-1"
                :class="{ 'icon-rtl': lang === 'ar' }"
                @click="show2 = !show2"
              >
                {{ show2 ? "visibility_off" : "visibility" }}
              </v-icon>
            </v-flex>
            <v-flex md6 xs12>
              <v-select
                box
                @change="saveToLocalStorage('city', $event)"
                :items="cities"
                item-text="label"
                item-value="value"
                :label="$t('city')"
                v-model="city"
                :rules="[rules.required]"
                required
                autocomplete
              ></v-select>
            </v-flex>
            <v-flex md6 xs12>
              <v-select
                :nudge-width="500"
                box
                :items="nationalities"
                item-text="label"
                item-value="value"
                :label="$t('nationality')"
                v-model="nationality"
                :rules="[rules.required]"
                required
                autocomplete
                @change="saveToLocalStorage('nationality', $event)"
              ></v-select>
            </v-flex>
            <v-flex md6 xs12>
              <v-text-field
                box
                :label="$t('referralCode')"
                v-model="referredBy"
              ></v-text-field>
            </v-flex>
            <v-flex md6 xs12>
              <v-select
                box
                @change="saveToLocalStorage('isOwner', $event)"
                :items="[
                  { label: $t('yes'), value: 'true' },
                  { label: $t('no'), value: 'false' }
                ]"
                :rules="[rules.required]"
                item-text="label"
                item-value="value"
                :label="$t('car owner')"
                v-model="isOwner"
                required
                autocomplete
              ></v-select>
            </v-flex>
            <v-flex xs12>
              <v-checkbox
                :label="$t('agreeTerms')"
                v-model="agreeTerms"
                required
                :rules="[rules.required]"
              ></v-checkbox>
            </v-flex>
          </v-form>
        </v-layout>
        <v-layout
          key="2"
          v-else-if="current === 1"
          wrap
          style="margin-top: 40px"
        >
          <v-flex
            md5
            xs12
            offset-md1
            v-for="(image, index) in documents"
            v-if="image.display"
            column
            class="align-center mt-3"
            :key="index"
          >
            <input
              type="file"
              @change="filePicked($event, index)"
              style="display: none"
              accept="image/*"
              :ref="image.identifier"
            />
            <div class="image-holder-2" @click="pickFile(image.identifier)">
              <div :key="`placeholder${index}`">
                <v-icon class="upload-icon">attach_file</v-icon>
                <span class="image-caption mt-1">{{ image.imgTitle }}</span>
                <v-progress-circular
                  :size="30"
                  indeterminate
                  color="primary"
                  v-if="documents[index].loadingStatus.loading"
                ></v-progress-circular>
                <v-icon
                  color="green"
                  v-if="documents[index].loadingStatus.success"
                  >check</v-icon
                >
                <v-icon color="red" v-if="documents[index].loadingStatus.error"
                  >close</v-icon
                >
                <v-btn
                  class="primary"
                  v-if="image.imgSrc"
                  @click.stop="viewImage(image.imgSrc, image.identifier)"
                  >{{ $t("view") }}</v-btn
                >
              </div>
            </div>
          </v-flex>
        </v-layout>
      </transition>
      <button
        class="next"
        @click="next"
        style="margin: 12px auto"
        id="driver_complete_registration_ego"
        v-if="current === 1"
      >
        {{ $t("finish") }}
      </button>
      <button class="next" @click="next" style="margin: 12px auto" v-else>
        {{ $t("next") }}
      </button>
      <v-snackbar
        :timeout="3000"
        v-model="dataSuccessful"
        color="green"
        :left="true"
        :top="true"
      >
        {{ $t("dataSuccessful") }}
        <v-btn flat color="black" @click.native="code409 = false">Close</v-btn>
      </v-snackbar>
      <v-snackbar :timeout="3000" v-model="code409" color="red">
        {{ code409Text }}
        <v-btn flat color="black" @click.native="code409 = false">Close</v-btn>
      </v-snackbar>
      <v-snackbar :timeout="3000" v-model="imageSnack" color="red">
        {{ $t("upload photos") }}
        <v-btn flat color="black" @click.native="imageSnack = false"
          >Close</v-btn
        >
      </v-snackbar>
      <v-snackbar :timeout="3000" v-model="imageSnackSize" color="red">
        {{ $t("upload photos size") }}
        <v-btn flat color="black" @click.native="imageSnackSize = false"
          >Close</v-btn
        >
      </v-snackbar>
    </v-container>
    <v-container
      key="success"
      v-if="current === 2"
      :class="{ rtl: lang === 'ar' }"
      class="steps"
    >
      <v-layout column align-center>
        <p class="text" style="font-size: 35px">
          {{ $t("client registration success") }}
        </p>
        <p class="text" style="font-size: 25px">
          {{ $t("registration success") }}
        </p>
        <div class="mt-0">
          <h1 class="title-2 mb-2=" style="text-align: center">
            {{ $t("downloadDriverApp") }}
          </h1>
          <v-layout wrap>
            <v-flex
              xs12
              md3
              :style="{
                'text-align': 'center',
                width: '100%',
                'margin-left': lang === 'en' ? 'auto' : 0,
                'margin-right': lang === 'ar' ? 'auto' : 0
              }"
            >
              <a :href="androidAppUrl" target="_blank" class="android-driver">
                <img
                  v-if="lang === 'ar'"
                  style="width: 100%"
                  src="../../../static/become-driver/google-play-badge.png"
                  alt=""
                />
                <img
                  v-if="lang === 'en'"
                  style="width: 100%"
                  src="../../../static/become-driver/google-play-badge (en).png"
                  alt=""
                />
              </a>
            </v-flex>
            <v-flex
              xs12
              md3
              :style="{
                'text-align': 'center',
                width: '100%',
                'margin-right': lang === 'en' ? 'auto' : 0,
                'margin-left': lang === 'ar' ? 'auto' : 0
              }"
            >
              <a :href="iosAppUrl" target="_blank" class="ios-driver">
                <img
                  v-if="lang === 'ar'"
                  style="width: 100%"
                  src="../../../static/become-driver/app-store-badge-ar.png"
                  alt=""
                />
                <img
                  v-if="lang === 'en'"
                  style="width: 100%"
                  src="../../../static/become-driver/app-store-badge-en.png"
                  alt=""
                />
              </a>
            </v-flex>
          </v-layout>
        </div>
      </v-layout>
    </v-container>
  </transition>
</template>

<script>
/* eslint-disable no-param-reassign,no-unused-expressions */
import ImageCompressor from "image-compressor.js";

import config from "../../config";
import HTTP from "../../http-common";
import storage from "../../storage";

export default {
  metaInfo: {
    title: "Ego | Driver Registration"
  },
  data() {
    return {
      show1: false,
      show2: false,
      viewImageDialog: false,
      viewImageSrc: "",
      viewImageTip: "",
      agreeTerms: false,
      validForm1: false,
      countries: [],
      nationalities: [],
      city: localStorage.city,
      email: localStorage.email,
      firstName: localStorage.firstName,
      referredBy: storage.referredBy(),
      language: this.lang,
      lastName: localStorage.lastName,
      nationality: localStorage.nationality,
      password: "",
      passwordConfirmation: "",
      isOwner: localStorage.isOwner || "",
      current: 0,
      idImage: "",
      driver: null,
      vehicle: null,
      rules: {
        required: value => !!value || this.$t("required"),
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || this.$t("invalidEmail");
        },
        emailLess: value => value.length < 254 || this.$t("lessEmail"),
        nameMore: value => value.length > 2 || this.$t("moreLetters"),
        nameless: value => value.length < 26 || this.$t("lessLetters"),
        letters: value => {
          const pattern = /^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z-_]*$/;
          return pattern.test(value) || this.$t("invalidName");
        }
      },
      imageSnack: false,
      imageSnackSize: false,
      dataSuccessful: false,
      documents: [],
      code409: false,
      code409Text: "",
      androidAppUrl: config.androidAppUrl("driver"),
      iosAppUrl: config.iosAppUrl("driver")
    };
  },
  computed: {
    cities() {
      const index = this.countries.findIndex(
        country => country.value === localStorage.country
      );
      if (index === -1) return [];
      return this.countries[index].cities;
    },
    lang() {
      return localStorage.lang || this.$route.path.substring(1, 3);
    }
  },
  methods: {
    viewImage(src, tip) {
      this.viewImageDialog = true;
      this.viewImageSrc = src;
      this.viewImageTip = tip;
    },
    saveToLocalStorage(label, data) {
      localStorage[label] = data;
    },
    next() {
      if (this.current === 0) this.submit1();
      if (this.current === 1) this.submit2();
    },
    async submit1() {
      if (this.$refs.form1.validate()) {
        const data = {
          device: "web",
          deviceId: null,
          driver: {
            city: this.city, // required
            country: localStorage.country,
            nationality: this.nationality, // required
            email: this.email, // required
            password: this.password, // required
            passwordConfirmation: this.passwordConfirmation, // required
            firstName: this.firstName, // required
            referredBy: this.referredBy,
            language: this.lang, // required
            lastName: this.lastName // required
          },
          vehicle: {
            isOwner: this.isOwner
          }
        };
        HTTP.post("/drivers/simple-registration", data)
          .then(response => {
            this.current = 1;
            localStorage.driverId = response.data.data.driver.id;
            localStorage.vehicleId = response.data.data.vehicle.id;
            HTTP.defaults.headers["X-Access-Token"] =
              response.data.session.accessToken;
            localStorage.accessToken = response.data.session.accessToken;
            localStorage.step = 1;
            localStorage.current = 1;
            this.dataSuccessful = true;
            localStorage.city = "";
            localStorage.country = "";
            localStorage.email = "";
            localStorage.firstName = "";
            localStorage.lastName = "";
            localStorage.nationality = "";

            HTTP.get(`/drivers/${localStorage.driverId}`).then(response => {
              const driver = response.data.data;
              this.driver = driver;
              HTTP.get(`/vehicles/${driver.currentVehicleId}`).then(
                vehicleResponse => {
                  const vehicle = vehicleResponse.data.data;
                  this.vehicle = vehicle;
                  this.isOwner = vehicle.isOwner.toString();

                  this.fillDocumentsArray();
                }
              );
            });
          })
          .catch(error => {
            console.log(error);
            if (error.response.data.code === 409) {
              const errorType = error.response.data.key;
              this.code409Text = `${this.$t(errorType)} ${this.$t("inUse")}`;
              this.code409 = true;
            }
            if (error.response.data.code === 401) {
              this.code409Text = `${this.$t("sessionExpired")}`;
              this.code409 = true;
              this.$router.push(`/${this.lang}/driver-register`);
            }
            if (error.response.data.code === 400) {
              this.$refs.password.errorMessages = [
                error.response.data.errorsWithMessages.password
              ];
            }
          });

        mixpanel.identify(response.data.data.driver.id);
        //mixpanel integration
        mixpanel.track("Personal Information Completed", {
          $first_name: response.data.data.driver.firstName,
          $last_name: response.data.data.driver.lastName,
          $email: response.data.data.driver.email,
          $city: response.data.data.driver.city,
          Nationality: response.data.data.driver.nationality,
          "Referral Code": this.referredBy,
          "Vehicle Owner?": response.data.data.vehicle.isOwner ? "Yes" : "No",
          "Driver ID": response.data.data.driver.id
        });
        mixpanel.people.set({
          $first_name: response.data.data.driver.firstName
        });
        mixpanel.people.set({
          $last_name: response.data.data.driver.lastName
        });
        mixpanel.people.set({ $email: response.data.data.driver.email });
        mixpanel.people.set({ $city: response.data.data.driver.city });
        mixpanel.people.set({
          Nationality: response.data.data.driver.nationality
        });
        mixpanel.people.set({
          "Referral Code": response.data.data.driver.referredBy
        });
        mixpanel.people.set({
          "Vehicle Owner?": response.data.data.vehicle.isOwner ? "Yes" : "No"
        });
        mixpanel.people.set({ "Driver ID": response.data.data.driver.id });
        mixpanel.people.set({ "User ID": response.data.data.driver.id });
      }
    },
    submit2() {
      const checkArray = this.documents.map(
        image => image.loadingStatus.success
      );
      if (checkArray.every(state => state === true)) {
        this.current = 2;
        localStorage.current = 2;
        localStorage.step = 2;
        window.scrollTo(0, 0);
        this.$confetti.start();
        setTimeout(() => {
          this.$confetti.stop();
        }, 3000);

        //mixpanel integration
        mixpanel.reset();
      } else {
        this.imageSnack = true;
      }
    },
    pickFile(identifier) {
      this.$refs[identifier][0].click();
    },
    filePicked(event, index) {
      this.documents[index].loadingStatus.loading = true;
      this.documents[index].loadingStatus.error = false;
      const files = event.target.files;
      if (!files[0]) return;
      const fileReader = new FileReader();
      fileReader.addEventListener("load", () => {
        this.documents[index].imgSrc = fileReader.result;
      });
      fileReader.readAsDataURL(files[0]);
      const imageCompressor = new ImageCompressor();
      imageCompressor
        .compress(files[0], {
          convertSize: 1000000,
          quality: 0.2
        })
        .then(result => {
          const formData = new FormData();
          formData.append(
            this.documents[index].identifier,
            result,
            result.fileName
          );
          HTTP.put(
            `/${this.documents[index].resource}s/${
              this[this.documents[index].resource].id
            }/photos/${this.documents[index].identifier}`,
            formData
          )
            .then(() => {
              this.documents[index].loadingStatus.loading = false;
              this.documents[index].loadingStatus.error = false;
              this.documents[index].loadingStatus.success = true;
            })
            .catch(error => {
              console.log(error);
              this.documents[index].loadingStatus.loading = false;
              this.documents[index].loadingStatus.error = true;
              this.documents[index].loadingStatus.success = false;
              this.documents[index].imageScr = "";
              if (error.response.data.code === 401) {
                this.code409Text = `${this.$t("sessionExpired")}`;
                this.code409 = true;
                this.$router.push(`/${this.lang}/driver-register`);
              }
            });
        });
    },
    fillDocumentsArray() {
      const documents = [];
      this.driver.documents.forEach(doc => {
        documents.push({
          loadingStatus: {
            loading: false,
            error: false,
            success: doc.exists || (doc.nonOwnerOnly && this.vehicle.isOwner)
          },
          resource: "driver",
          imgSrc: "",
          imgTitle: doc.label,
          identifier: doc.value,
          display:
            !doc.nonOwnerOnly || (doc.nonOwnerOnly && !this.vehicle.isOwner)
        });
      });
      this.vehicle.documents.forEach(doc => {
        documents.push({
          loadingStatus: {
            loading: false,
            error: false,
            success: doc.exists || (doc.nonOwnerOnly && this.vehicle.isOwner)
          },
          resource: "vehicle",
          imgSrc: "",
          imgTitle: doc.label,
          identifier: doc.value,
          display:
            !doc.nonOwnerOnly || (doc.nonOwnerOnly && !this.vehicle.isOwner)
        });
      });

      this.documents = documents;
    }
  },
  beforeDestroy() {
    window.beforeunload = null;
  },
  async created() {
    HTTP.defaults.headers["X-Access-Token"] = localStorage.accessToken;
    this.current = +localStorage.step;
    if (isNaN(this.current)) {
      this.$router.push(`/${this.lang}/driver-register`);
    }
    if (this.current === 1) {
      HTTP.get(`/drivers/${localStorage.driverId}`).then(response => {
        const driver = response.data.data;
        this.driver = driver;
        HTTP.get(`/vehicles/${driver.currentVehicleId}`).then(
          vehicleResponse => {
            const vehicle = vehicleResponse.data.data;
            this.vehicle = vehicle;
            this.isOwner = vehicle.isOwner.toString();

            this.fillDocumentsArray();
          }
        );
      });
      mixpanel.identify(driver.id);
    }
    const countriesResponse = await HTTP.get("/options/countries");
    const nationalitiesResponse = await HTTP.get("/options/nationalities");

    this.countries = countriesResponse.data.data;
    this.nationalities = nationalitiesResponse.data.data;
  },
  mounted() {
    this.$vuetify.goTo(257, { easing: "easeInOutCubic", duration: 2000 });
  }
};
</script>

<style lang="scss" scoped>
.steps {
  padding-top: 210px;
  &.rtl {
    direction: rtl;
  }
}

.header {
  padding-top: 50px;
  padding-bottom: 60px;
  flex-direction: column;
  align-items: center;
  .header-line {
    width: 129px;
    height: 5px;
    background-color: #f18642;
    margin: 10px 0;
  }
  .header-title {
    width: 530px;
    font-family: Roboto;
    font-size: 42px;
    font-weight: 300;
    letter-spacing: 0.9px;
    text-align: center;
    color: #4a4a4a;
    @media screen and (max-width: 450px) {
      width: 200px;
      font-size: 30px;
    }
  }
}

.step-title {
  justify-content: center;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.3px;
  text-align: center;
  color: #4a4a4a;
}

.next {
  box-sizing: border-box;
  height: 55px;
  width: 348px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  background-color: #00ace6;
  font-family: Roboto;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 2.9px;
  color: #ffffff;
  transition: all 0.2s;
  &:hover {
    border: 6px solid rgb(207, 239, 250);
  }
  @media screen and (max-width: 450px) {
    width: 100%;
  }
}

.step-head {
  display: flex;
  justify-content: center;
  align-items: center;
  .guide {
    border-radius: 50%;
    width: 27px;
    height: 27px;
    background-color: #c2cad2;
    transition: all 0.3s;
    transition-delay: 0.7s;
  }
}

.step-head.step-head-active {
  .guide {
    border-radius: 50%;
    width: 45px;
    height: 45px;
    background-color: #fff;
    box-shadow: 0 0 20px 0 lightgray;
    border: 10px solid #00ace6;
  }
}

.step-line {
  display: flex;
  align-items: center;
  .line {
    height: 3px;
    background-color: #c2cad2;
    flex: 1;
    transition: background-color 0.3s;
    transition-delay: 0.7s;
  }
}

.step-line.step-line-active {
  .line {
    background-color: #00ace6;
  }
}

.slide2-enter-active {
  animation: slide-reverse-2 0.5s;
}

.slide2-leave-active {
  animation: slide-2 0.5s;
}

@keyframes slide-2 {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(500px);
    opacity: 0;
  }
}

@keyframes slide-reverse-2 {
  100% {
    transform: translateX(0);
    opacity: 1;
  }
  0% {
    transform: translateX(-500px);
    opacity: 0;
  }
}

@media screen and (max-width: 450px) {
  @keyframes slide-2 {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @keyframes slide-reverse-2 {
    100% {
      opacity: 1;
    }
    0% {
      opacity: 0;
    }
  }
}

.at-input,
.at-select {
  margin: 25px 30px;
}

.image-holder-1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    @media screen and (max-width: 450px) {
      margin: 0 20px;
    }
  }
}

.image-holder-2 {
  display: flex;
  align-items: center;
  border-radius: 12px;
  background-color: #eff7ff;
  border: dashed 2px #00ace6;
  font-family: Roboto;
  font-size: 20px;
  color: #00ace6;
  margin-bottom: 20px;
  @media screen and (max-width: 450px) {
    font-size: 16px;
  }
  .upload-icon {
    padding: 20px;
    font-size: 50px;
    color: #00ace6;
    cursor: pointer;
    @media screen and (max-width: 450px) {
      font-size: 30px;
      padding: 20px 5px;
    }
  }
}

.image-title {
  opacity: 0.51;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.55;
  letter-spacing: 0.2px;
  text-align: center;
  color: #4a4a4a;
  margin-top: 10px;
}

.text {
  font-family: Roboto;
  font-size: 42px;
  font-weight: 300;
  letter-spacing: 0.9px;
  text-align: center;
  color: #4a4a4a;
  margin-bottom: 100px;
}
.icon-1 {
  position: absolute;
  top: 23%;
  left: 80%;
  cursor: pointer;
  &.icon-rtl {
    left: 20%;
  }
}

.social-media-btn {
  /*display: flex;*/
  /*height: 52px;*/
  text-decoration: none;
  width: 90%;
  max-width: 220px;
  position: relative;
  border-radius: 6px;
  background-color: #ffffff;
  padding: 8px 35px 8px 50px;
  box-shadow: 0 7px 20px 0 rgba(0, 0, 0, 0.19);
  font-family: Roboto;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.4px;
  color: #000;
  transition: all 0.2s;
  @media screen and (max-width: 450px) {
    width: 90%;
    font-size: 15px;
    margin-top: 10px;
  }
  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 14px 20px 0 rgba(0, 0, 0, 0.19);
  }
  &:active {
    transform: translateY(-1px);
    box-shadow: 0 7px 20px 0 rgba(0, 0, 0, 0.19);
  }
  .icon {
    width: 30px;
    position: absolute;
    left: 15px;
    top: 18px;
    @media screen and (max-width: 450px) {
      top: 12px;
    }
  }
  .btn-content {
    display: flex;
    flex-direction: column;
    text-align: left;
    .btn-top {
      font-size: 13px;
    }
  }
}
</style>
