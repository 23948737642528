<template>
  <v-container fluid class="header" :class="{ rtl: lang == 'ar' }">
    <v-layout align="center" style="width: 100%">
      <v-flex
        v-if="token && role && !isSuccess"
        class="left-container"
        style="
          text-align: center;
          width: 100%;
          margin-left: auto;
          margin-right: auto;
        "
        md5
        xs12
        justify-center
        key="slide-3"
      >
        <p class="title-2">{{ $t("resetPassword") }}</p>
        <div class="phone-holder">
          <input
            type="password"
            :placeholder="$t('enter password')"
            class="phone-input"
            v-model="passwordReset"
            @keypress.enter="resetPasswordByLink"
          />
        </div>

        <div class="phone-holder">
          <input
            type="password"
            :placeholder="$t('confirm password')"
            class="phone-input"
            v-model="passwordResetConfirm"
            @keypress.enter="resetPasswordByLink"
          />
        </div>
        <p class="error-message" style="color: white">
          {{ confirmPasswordError }}
        </p>

        <button
          class="become-driver"
          @click="resetPasswordByLink"
          :disabled="confirmPasswordError !== ''"
        >
          {{ $t("resetPassword") }}
        </button>
      </v-flex>
      <v-flex
        v-else-if="isSuccess"
        class="left-container"
        style="
          text-align: center;
          width: 100%;
          margin-left: auto;
          margin-right: auto;
        "
        md5
        xs12
        justify-center
        key="slide-3"
      >
        <p class="title-2">{{ $t("ResetPasswordSuccessfully") }}</p>
      </v-flex>
      <v-flex
        v-else
        class="left-container"
        style="
          text-align: center;
          width: 100%;
          margin-left: auto;
          margin-right: auto;
        "
        md5
        xs12
        justify-center
        key="slide-3"
      >
        <p class="title-2">{{ $t("invalidResetPasswordLink") }}</p>
      </v-flex>
    </v-layout>
    <v-snackbar
      :timeout="3000"
      v-model="requestErrorPresent"
      style="left: 0; top: 70%"
    >
      {{ requestErrorMessage }}
      <v-btn flat color="pink" @click.native="requestErrorPresent = false">{{
        $t("close")
      }}</v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import "../../static/become-driver/font/flaticon2.css";

import backend from "../backend";

export default {
  name: "ResetPasswordByToken",
  props: {
    token: { required: true, type: String },
    role: { required: true, type: String },
  },
  data() {
    return {
      passwordReset: "",
      passwordResetConfirm: "",
      requestErrorPresent: false,
      requestErrorMessage: "",
      isSuccess: false,
    };
  },
  mounted() {},
  methods: {
    async resetPasswordByLink() {
      const { token, role } = this;
      const { lang, passwordReset } = this;

      try {
        await backend.resetPasswordByLink(token, passwordReset, lang, role);
        this.isSuccess = true;
      } catch (err) {
        this.handleRequestError(err);
      }
    },

    handleRequestError(err) {
      this.requestErrorMessage = err.message;
      this.requestErrorPresent = true;
    },
  },
  computed: {
    confirmPasswordError() {
      if (this.passwordReset !== this.passwordResetConfirm) {
        return this.$t("passwordMatch");
      }
      return "";
    },
    lang() {
      return localStorage.lang || this.$route.path.substring(1, 3);
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  padding-top: 150px;
  padding-bottom: 480px;
  background-image: linear-gradient(42deg, #007b94, #016f9e 88%, #006492);
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  height: fit-content;
  &.rtl {
    direction: rtl;
  }
  @media screen and (max-width: 450px) {
    padding-top: 50px;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    // height: 1400px;
    padding-bottom: 50px;
  }
}

.header-pic {
  position: relative;
  object-fit: contain;
  top: 70px;
  right: -17%;
  /*width: 78%*/
  animation: rotate 300s infinite linear alternate-reverse;
  &.rtl {
    right: 17%;
    top: 150px;
    @media screen and (max-width: 450px) {
      right: 0;
      top: 0;
      width: 100%;
    }
  }
  @media screen and (max-width: 450px) {
    right: 0;
    top: 0;
    width: 100%;
  }
}
.header-pic-location {
  position: absolute;
  top: 15%;
  left: 45%;
  animation: jump 5s infinite;
  &.rtl {
    top: 30%;
    left: 42%;
    @media screen and (max-width: 450px) {
      width: 10%;
      top: 15%;
      left: 45%;
    }
  }
  @media screen and (max-width: 450px) {
    width: 10%;
    top: 15%;
    left: 45%;
  }
}

.android-download {
  width: 100%;
}
.ios-download {
  width: 100%;
}

.left-container {
  padding-top: 190px;
  padding-left: 5%;
  padding-right: 3%;
}
.column {
  display: flex;
  flex-direction: column;
}
.flaticon {
  text-align: center;
  color: #fff;
  &:before {
    font-size: 60px;
  }
}
.confirmation-input {
  width: 62px;
  height: 47px;
  border-radius: 6px;
  background-color: #ffffff;
  border: solid 1px rgba(168, 168, 168, 0.69);
  text-align: center;
}
.phone-holder {
  display: flex;
  align-items: center;
  border-radius: 8px;
  background-color: #ffffff;
  padding: 20px;
  margin-bottom: 20px;
  // width: 370px;
  /*width: 80%;*/
  .icon {
    width: 30px;
    margin-right: 10px;
  }
  .code-holder {
    opacity: 0.4;
    font-family: Roboto;
    font-size: 16px;
    color: #9b9b9b;
    margin-right: 10px;
  }
  .form-divider {
    width: 1px;
    flex: 1px 0 0;
    height: 19px;
    background-color: #979797;
    margin-right: 10px;
  }
  .phone-input {
    outline: none;
    margin-right: 10px;
  }
  @media screen and (max-width: 450px) {
    width: 100%;
    .code-holder {
      font-size: 12px;
    }
    .icon {
      width: 25px;
    }
    .phone-input {
      font-size: 20px;
    }
  }
}
.phone-holder-reg {
  display: flex;
  align-items: center;
  border-radius: 8px;
  background-color: #ffffff;
  padding: 20px;
  margin: auto;
  width: 100%;
  /*width: 80%;*/
  .icon {
    width: 30px;
    margin-right: 10px;
  }
  .code-holder {
    opacity: 0.4;
    font-family: Roboto;
    font-size: 16px;
    color: #9b9b9b;
    margin-right: 10px;
  }
  .form-divider {
    width: 1px;
    flex: 1px 0 0;
    height: 19px;
    background-color: #979797;
    margin-right: 10px;
  }
  .phone-input {
    outline: none;
    margin-right: 10px;
  }
  @media screen and (max-width: 450px) {
    width: 100%;
    .code-holder {
      font-size: 12px;
    }
    .icon {
      width: 25px;
    }
    .phone-input {
      font-size: 20px;
    }
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.title-1 {
  font-family: Roboto;
  font-size: 64px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 1.4px;
  color: #ffffff;
  @media screen and (max-width: 450px) {
    font-size: 30px;
    padding-bottom: 10px;
  }
}
.title-2 {
  font-family: Roboto;
  font-size: 30px;
  font-weight: 300;
  letter-spacing: 0.8px;
  color: #ffffff;
  margin-bottom: 45px;
  @media screen and (max-width: 450px) {
    font-size: 25px;
    /*text-align: center;*/
  }
}
.title-3 {
  font-family: Roboto;
  font-size: 25px;
  font-weight: 300;
  letter-spacing: 0.8px;
  color: #ffffff;
  margin-bottom: 45px;
  @media screen and (max-width: 450px) {
    font-size: 20px;
    /*text-align: center;*/
  }
}
.text {
  font-family: Roboto;
  letter-spacing: 1px;
  font-weight: 400;
  line-height: 50px;
  font-size: 35px;
  color: #ffffff;
  @media screen and (max-width: 450px) {
    font-size: 17px;
  }
}

.become-driver {
  min-width: 370px;
  padding: 20px 110px;
  border-radius: 8px;
  background-color: #1d4d5c;
  box-shadow: 0 4px 0 0 #068bc6;
  font-family: Roboto;
  font-size: 23px;
  color: #f2f2f2;
  transition: background-color 0.5s;
  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 14px 20px 0 rgba(0, 0, 0, 0.19);
  }
  &:focus {
    background-color: #22a68d;
    transform: translateY(-3px);
    box-shadow: 0 14px 20px 0 rgba(0, 0, 0, 0.19);
  }
  &:active {
    transform: translateY(-1px);
    box-shadow: 0 7px 20px 0 rgba(0, 0, 0, 0.19);
  }
  &[disabled] {
    background-color: #777 !important;
    transform: none;
    box-shadow: none;
  }
  @media screen and (max-width: 450px) {
    min-width: 100%;
    padding: 20px 3px;
  }
}
.become-driver-reg {
  width: 100%;
  padding: 10px 80px;
  border-radius: 8px;
  background-color: #1d4d5c;
  font-family: Roboto;
  font-size: 23px;
  color: #f2f2f2;
  transition: background-color 0.5s;
  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 14px 20px 0 rgba(0, 0, 0, 0.19);
  }
  &:focus {
    background-color: #22a68d;
    transform: translateY(-3px);
    box-shadow: 0 14px 20px 0 rgba(0, 0, 0, 0.19);
  }
  &:active {
    transform: translateY(-1px);
    box-shadow: 0 7px 20px 0 rgba(0, 0, 0, 0.19);
  }
  &[disabled] {
    background-color: #777 !important;
    transform: none;
    box-shadow: none;
  }
  @media screen and (max-width: 450px) {
    min-width: 100%;
    padding: 10px 3px;
  }
}
.otp-holder {
  @media screen and (max-width: 450px) {
    margin-bottom: 20px;
  }
}
.fade-enter-active {
  animation: fade 0.5s;
}
.fade-leave-active {
  animation: fade 0.5s reverse;
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes rotate {
  0% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(3600deg);
  }
}
@keyframes jump {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(50px);
  }
  100% {
    transform: translateY(0);
  }
}
.forget-password {
  text-decoration: none;
  display: block;
  font-size: 20px;
  padding-top: 10px;
  color: white;
  transition: transform 0.5s;
  cursor: pointer;
  // position: absolute;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  &:hover {
    /*font-size: 25px;*/
    transform: scale(1.2);
  }
}
.arabicIcon {
  transform: translateX(-90%) !important;
}

.social-media-btn {
  /*display: flex;*/
  /*height: 52px;*/
  text-decoration: none;
  width: 90%;
  max-width: 220px;
  position: relative;
  border-radius: 6px;
  background-color: #ffffff;
  padding: 8px 35px 8px 50px;
  box-shadow: 0 7px 20px 0 rgba(0, 0, 0, 0.19);
  font-family: Roboto;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.4px;
  color: #000;
  transition: all 0.2s;
  @media screen and (max-width: 450px) {
    width: 90%;
    font-size: 15px;
    margin-top: 10px;
  }
  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 14px 20px 0 rgba(0, 0, 0, 0.19);
  }
  &:active {
    transform: translateY(-1px);
    box-shadow: 0 7px 20px 0 rgba(0, 0, 0, 0.19);
  }
  .icon {
    width: 30px;
    position: absolute;
    left: 15px;
    top: 18px;
    @media screen and (max-width: 450px) {
      top: 12px;
    }
  }
  .btn-content {
    display: flex;
    flex-direction: column;
    text-align: left;
    .btn-top {
      font-size: 13px;
    }
  }
}
</style>
