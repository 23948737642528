const isProduction = window.location.host.includes("ego-now.com");
const isLocal = window.location.host.includes("localhost");

export default {
  androidAppUrl(role) {
    return role === "client"
      ? "https://play.google.com/store/apps/details?id=ego.now.client"
      : "https://play.google.com/store/apps/details?id=ego.now.driver";
  },
  apiBaseUrl() {
    return isProduction
      ? "https://api.ego-now.com/api/v0"
      : // : isLocal
        // ? "http://localhost:3000/api/v0"
        "https://api.procabnow.com/api/v0";
  },
  iosAppUrl(role) {
    return role === "client"
      ? "https://itunes.apple.com/us/app/ego-now/id1378953127?mt=8"
      : "https://itunes.apple.com/us/app/ego-driver/id1379066908?mt=8";
  }
};
