<template>

</template>

<script>
    export default {
      name: 'Partners',
    };
</script>

<style scoped>

</style>
