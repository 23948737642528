// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from "vue";
import {
  Vuetify,
  VApp,
  VGrid,
  VIcon,
  VSnackbar,
  VBtn,
  VTextField,
  VForm,
  VSelect,
  VMenu,
  VDatePicker,
  VAvatar,
  VNavigationDrawer,
  VList,
  VDivider,
  VToolbar,
  VProgressCircular,
  VCheckbox,
  VDialog,
  VTooltip,
} from "vuetify";
import { Scroll } from "vuetify/es5/directives";
import Vuex from "vuex";
import vuexI18n from "vuex-i18n";
import ScrollReaveal from "scrollreveal";
import KsVueScrollmagic from "ks-vue-scrollmagic";

import App from "./App";
import router from "./router";

import "../node_modules/vuetify/src/stylus/app.styl";

import translationsEn from "./translations/translationsEn";
import translationsAr from "./translations/translationsAr";
import VueConfetti from "vue-confetti";

import HTTP from "./http-common";

import VueCountryCode from "vue-country-code";

Vue.use(VueCountryCode);

Vue.use(VueConfetti);
Vue.use(Vuetify, {
  components: {
    VApp,
    VGrid,
    VIcon,
    VSnackbar,
    VBtn,
    VTextField,
    VForm,
    VSelect,
    VMenu,
    VDatePicker,
    VAvatar,
    VNavigationDrawer,
    VList,
    VDivider,
    VToolbar,
    VProgressCircular,
    VCheckbox,
    VDialog,
    VTooltip,
  },
  directives: {
    Scroll,
  },
});

const store = new Vuex.Store();
Vue.use(vuexI18n.plugin, store);

Vue.i18n.add("en", translationsEn);
Vue.i18n.add("ar", translationsAr);

Vue.i18n.set(`${localStorage.lang || "en"}`);

Vue.use(KsVueScrollmagic);

Vue.config.productionTip = false;

Vue.directive("reveal", {
  inserted(el, binding = {}) {
    ScrollReaveal().reveal(el, binding.value);
  },
});

//
// router.beforeEach((to, from, next) => {
//   // use the language from the routing param or default language
//   console.log(to);
//   let language = to.params.lang;
//   if (language !== 'en' && language !== 'ar') {
//     language = localStorage.lang || 'en';
//     Vue.i18n.set(language);
//     console.log(`/${language}${to.path}`);
//     next(`/${language}${to.path}`);
//   }
//
//   // set the current language for vuex-i18n. note that translation data
//   // for the language might need to be loaded first
//   Vue.i18n.set(language);
//   next();
// });

/* eslint-disable no-new */
new Vue({
  store,
  el: "#app",
  router,
  components: { App },
  template: "<App/>",
  created() {
    if (!localStorage.lang || !['ar','en'].includes(localStorage.lang)) {
      this.$router.push("/en");
      localStorage.lang = "en";
    }
    const paramsLang = this.$route.params.lang;
    if (paramsLang === "ar" || paramsLang === "en") {
      localStorage.lang = paramsLang;
    }
    Vue.i18n.set(localStorage.lang);
    if (localStorage.lang === "ar") {
      document.body.className = "rtl";
    }
    HTTP.defaults.headers["X-Language"] = localStorage.lang;
    document.title =
      localStorage.lang === "ar"
        ? "ايجو | تطبيق ايجو لتوصيل المشاوير بطريقة آمنه وأسعار مناسبه"
        : "Ego | Ego app to deliver rids in a safe and affordable way";
  },
  watch: {
    "$route.path": function onChange(val) {
      if (val === "/ar") {
        localStorage.lang = "ar";
        Vue.i18n.set(localStorage.lang);
        document.body.className = "rtl";
        HTTP.defaults.headers["X-Language"] = localStorage.lang;
        document.title =
          "ايجو | تطبيق ايجو لتوصيل المشاوير بطريقة آمنه وأسعار مناسبه";
      } else if (val === "/en") {
        localStorage.lang = "en";
        Vue.i18n.set(localStorage.lang);
        document.body.className = "";
        HTTP.defaults.headers["X-Language"] = localStorage.lang;
        document.title =
          "Ego | Ego app to deliver rids in a safe and affordable way";
      }
    },
  },
});
