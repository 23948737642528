<template>
  <div>
    <account-gate role="driver"></account-gate>
  </div>
</template>

<script>
import storage from "../../storage";

import AccountGate from "../AccountGate";

export default {
  name: "BecomeDriver",
  props: {
    referredBy: { required: false, type: String, default: "" }
  },
  metaInfo: {
    title:
      localStorage.lang === "ar"
        ? " ايجو | كن القائد وابدأ في جني الأموال الآن عن طريق التسجيل في تطبيق ايجو القائد"
        : "Ego | make money and be your own boss by registering in ego driver application"
  },
  components: {
    AccountGate
  },
  created() {
    const { referredBy } = this;

    if (!referredBy) {
      return;
    }

    storage.referredBy(referredBy);

    const descEl = document.querySelector('head meta[name="description"]');

    descEl.setAttribute(
      "content",
      localStorage.lang === "ar"
        ? "ابدأ في كسب المال عن طريق سيارتك من خلال تقديم خدمات التوصيل في أي وقت ز يحصل القائد على أعلى نسبة عمولة وتفير خدمة الإكرامياتز تتيح لك هذه المهنة أن تكون القائد الخاص لنفسك!"
        : "Start making money with your car by offering drop-off services anytime. The drivers get highest commission rate from ego and tips services are provided. The profession allows you to be your own boss"
    );
  },
  mounted() {
    // mixpanal integration
    // Enabling the debug mode flag is useful during implementation,
    // but it's recommended you remove it for production

    if (window.location.host.includes("ego-now.com")) {
      mixpanel.init("50bd98a9c12182f6200521901c1619cc"); //prod
    } else {
      mixpanel.init("4e0663abf706c1085dadf622581e77eb", { debug: true });
    }
    mixpanel.track("Become A Driver");
  }
};
</script>

<style scoped>
.top {
  padding-top: 210px;
  height: 1225px;
  background-image: linear-gradient(318deg, #00caf1, #00a0e2 88%, #009ae0);
}
</style>
