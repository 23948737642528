<template>
  <v-container fluid class="header" :class="{ rtl: lang == 'ar' }">
    <h1>{{ $t("privacyTitle") }}</h1>
    <p>{{ $t("privacyParagraph") }}</p>
    <h2>{{ $t("privacyPoint1Title") }}</h2>
    <p>{{ $t("privacyPoint1Paragraph") }}</p>
    <h2>{{ $t("privacyPoint2Title") }}</h2>
    <p>{{ $t("privacyPoint2Paragraph") }}</p>
    <h2>{{ $t("privacyPoint3Title") }}</h2>
    <p>{{ $t("privacyPoint3Paragraph") }}</p>
    <h2>{{ $t("privacyPoint3Point2Title") }}</h2>
    <p>{{ $t("privacyPoint3Point2Paragraph") }}</p>
    <h2>{{ $t("privacyPoint3Point3Title") }}</h2>
    <p>{{ $t("privacyPoint3Point3Paragraph") }}</p>
    <h2>{{ $t("privacyPoint4Title") }}</h2>
    <p>{{ $t("privacyPoint4Paragraph") }}</p>
    <h2>{{ $t("privacyPoint5Title") }}</h2>
    <p>{{ $t("privacyPoint5Paragraph") }}</p>
    <h2>{{ $t("privacyPoint6Title") }}</h2>
    <p>{{ $t("privacyPoint6Paragraph") }}</p>
    <h2>{{ $t("privacyPoint7Title") }}</h2>
    <p>{{ $t("privacyPoint7Paragraph") }}</p>
    <h2>{{ $t("privacyPoint8Title") }}</h2>
    <p>{{ $t("privacyPoint8Paragraph") }}</p>
    <h2>{{ $t("privacyPoint9Title") }}</h2>
    <p>{{ $t("privacyPoint9Paragraph") }}</p>
  </v-container>
</template>

<script>
export default {
  name: "Privacy",
  metaInfo: {
    title: "Ego | Privacy Policy",
  },
  computed: {
    lang() {
      return localStorage.lang || this.$route.path.substring(1, 3);
    },
  },
  mounted() {
    this.$vuetify.goTo(240, { easing: "easeInOutCubic", duration: 2000 });
  },
};
</script>

<style lang="scss" scoped>
.header {
  background-image: linear-gradient(318deg, #00caf1, #00a0e2 88%, #009ae0);
  color: #fff;
  padding: 250px 10%;
  h1 {
    font-size: 40px;
    text-align: center;
    padding-bottom: 20px;
  }
  h2 {
    font-size: 30px;
    line-height: 40px;
  }
  h3 {
    font-size: 25px;
  }
  p {
    font-size: 20px;
  }
  &.rtl {
    direction: rtl;
  }
  // @media screen and (max-width: 1264px) {
  //   padding: 150px 30px;
  // }
}
</style>
