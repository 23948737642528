<template>
  <div>
    <account-gate role="client"></account-gate>
  </div>
</template>

<script>
import storage from "../storage";

import AccountGate from "./AccountGate";

export default {
  name: "BecomeClient",
  props: {
    referredBy: { required: false, type: String, default: "" }
  },
  metaInfo: {
    title: "Ego | Become a client"
  },
  components: {
    AccountGate
  },
  created() {
    const { referredBy } = this;

    if (!referredBy) {
      return;
    }

    storage.referredBy(referredBy);
  },
  mounted() {
    //mixpanel Integration
    if (window.location.host.includes("ego-now.com")) {
      mixpanel.init("3459b8127da07feabc5921b2c34fbee4"); //prod
    } else {
      mixpanel.init("2c14189be953084d127fe6ba7dc9f499", { debug: true });
    }
    mixpanel.track("Become A Client");
  }
};
</script>

<style scoped>
.top {
  padding-top: 210px;
  height: 1225px;
  background-image: linear-gradient(318deg, #00caf1, #00a0e2 88%, #009ae0);
}
</style>
