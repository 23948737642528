<template>
  <v-container fluid class="pinContainer" v-scroll="onScroll">
    <div style="height: 100vh" >
      <v-layout
        wrap
        v-for="(p, index) in panels"
        class="panel"
        :class="`panel-${index}`"
        :key="index"
        style="max-width: 80%; margin: 0 auto;"
        :ref="`panel${index}`"
      >
        <v-flex class="main-image-container" xs12 md6>
          <img class="main-image" :src="p.image" alt="">
        </v-flex>
        <v-flex xs12 md6 class="right-container">
          <img class="icon" :src="p.icon" alt="">
          <h2 class="title">{{p[lang].title}}</h2>
          <p class="text">{{p[lang].text}}</p>
        </v-flex>
      </v-layout>
      <div  class="guide">
        <div class="inner-guide inner-guide-1">
          <div class="inner-guide-control" :style="{width: `${point1}px`, height: `${point1}px`}"></div>
        </div>
        <div class="inner-guide inner-guide-2">
          <div class="inner-guide-control" :style="{width: `${point2}px`, height: `${point2}px`}"></div>
        </div>
        <div class="inner-guide inner-guide-3">
          <div class="inner-guide-control" :style="{width: `${point3}px`, height: `${point3}px`}"></div>
        </div>
        <div class="inner-guide inner-guide-4">
          <div class="inner-guide-control" :style="{width: `${point4}px`, height: `${point4}px`}"></div>
        </div>
        <div class="inner-guide inner-guide-5">
          <div class="inner-guide-control" :style="{width: `${point5}px`, height: `${point5}px`}"></div>
        </div>
      </div>
      <!--<div  class="guide guide-small hidden-sm-and-up">-->
        <!--<div class="inner-guide inner-guide-1">-->
          <!--<div class="inner-guide-control" :style="{width: `${point1}px`, height: `${point1}px`}"></div>-->
        <!--</div>-->
        <!--<div class="inner-guide inner-guide-2">-->
          <!--<div class="inner-guide-control" :style="{width: `${point2}px`, height: `${point2}px`}"></div>-->
        <!--</div>-->
        <!--<div class="inner-guide inner-guide-3">-->
          <!--<div class="inner-guide-control" :style="{width: `${point3}px`, height: `${point3}px`}"></div>-->
        <!--</div>-->
        <!--<div class="inner-guide inner-guide-4">-->
          <!--<div class="inner-guide-control" :style="{width: `${point4}px`, height: `${point4}px`}"></div>-->
        <!--</div>-->
        <!--<div class="inner-guide inner-guide-5">-->
          <!--<div class="inner-guide-control" :style="{width: `${point5}px`, height: `${point5}px`}"></div>-->
        <!--</div>-->
      <!--</div>-->
    </div>
  </v-container>
</template>

<script>
    export default {
      name: 'Qualities',
      mounted() {
        this.$nextTick(this.pinContainerScene);
      },
      data() {
        return {
          point1: 40,
          point2: 0,
          point3: 0,
          point4: 0,
          point5: 0,
          panels: [
            {
              icon: '../../../static/home-page/icon-panel-1.svg',
              image: '../../../static/home-page/Screen_2.png',
              en: {
                text: 'Simple sleek design aiming to simplify the experience of ride requesting.',
                title: 'We make it simple',
              },
              ar: {
                text: 'تصميم أنيق و بسيط يهدف الى تسهيل تجربة طلب الرحلة.',
                title: 'جعلناه الأكثر سهولة',
              },
            },
            {
              icon: '../../../static/home-page/icon-panel-2.svg',
              image: '../../../static/home-page/Screen_3.png',
              en: {
                text: 'We know your daily routine and the places that you head to on daily basis. We will let you favorite them so that you can get there by a single tap.',
                title: 'We pay attention to your details',

              },
              ar: {
                title: 'نهتم بتفاصيلك',
                text: 'نحن على معرفة بالروتين اليومي الخاص بكم والأماكن التي تتجهون لها دوما, إيجو يمنحكم القدرة على تصنيف أماكنكم المفضلة لطلب الرحلة إلى وجهتكم التالية بنقرة واحدة.',
              },
            },
            {
              icon: '../../../static/home-page/icon-panel-3.svg',
              image: '../../../static/home-page/Screen_4.png',
              en: {
                text: 'Before you step a foot in one of our cars, Ego will provide you with full details about the driver, car, and the rating. Not only that but we also provide you with an option to cancel the ride in case anything happened and you can immediately report it.',
                title: 'We care about your safety',
              },
              ar: {
                title: 'نهتم بسلامتك',
                text: 'قبل أن تطأ قدمك مركبة إيجو, سنطلعك على كامل التفاصيل الخاصة بالسائق أو المركبة, والتقييم الخاص بالسائق. ليس هذا وحسب, بل يمكنك إلغاء الرحلة في حال حدوث أي مكروه والإبلاغ عنه بسلاسة.',
              },
            },
            {
              icon: '../../../static/home-page/icon-panel-4.svg',
              image: '../../../static/home-page/Screen_5.png',
              en: {
                text: 'We all have our moments of silence, our rough, exhausting days where we don’t feel like interacting with others or just prefer resting within our personal space. You can simply tap a button to ask for a quiet ride ',
                title: 'A Quiet world',
              },
              ar: {
                title: 'عالم صامت',
                text: 'لدى الجميع منا لحظات صمت, أيام عصيبة ومرهقة لا نود فيها التعامل مع اﻵخرين أو نكتفي بالراحة داخل محيطنا الشخصي. يمكنكم ببساطة النقر على زر واحد لطلب رحلة صامتة.',
              },
            },
            {
              icon: '../../../static/home-page/icon-panel-5.svg',
              image: '../../../static/home-page/Screen_1.png',
              en: {
                text: 'We care about displaying a correct, declarative receipt with a fare breakdown to make it easier for you to understand how you got charged.  We even save your remaining change to your personal secure wallet to use it in your next ride.',
                title: 'We make it crystal clear',
              },
              ar: {
                title: 'شفافية الكريستال',
                text: 'نهتم بإظهار فاتورة واضحة وصحيحة مع تجزئة الأجرة لنبسط لكم مفهوم حساب رحلتكم, ونقوم أيضا بالحفاظ على الصرف المتبقي في محفظتكم اﻵمنة لاستخدامها في رحلتكم التالية.',
              },
            },
          ],
        };
      },
      methods: {
        console() {
          // eslint-disable-next-line no-console
          console.log(event);
        },
        onScroll() {
          this.point1 = this.$refs.panel0[0].style.opacity * 40;
          this.point2 = this.$refs.panel1[0].style.opacity * 40;
          this.point3 = this.$refs.panel2[0].style.opacity * 40;
          this.point4 = this.$refs.panel3[0].style.opacity * 40;
          this.point5 = this.$refs.panel4[0].style.opacity * 40;
        },
        pinContainerScene() {
          const Length = this.panels.length;

          // Create a new Timeline (equivalent to new TimelineMax())
          const tl = new this.$gsap.TimelineMax();

          for (let i = 0; i < Length; i += 1) {
            if (i === 0) {
              tl.to(`.panel-${i}`, 1.5, { opacity: 0, ease: this.$gsap.Linear.easeNone });
            } else if (i === 4) {
              tl.fromTo(`.panel-${i}`, 0.75, { opacity: 0 }, { opacity: 1, ease: this.$gsap.Linear.easeNone });
            } else {
              tl.fromTo(`.panel-${i}`, 0.75, { opacity: 0 }, { opacity: 1, ease: this.$gsap.Linear.easeNone });
              tl.to(`.panel-${i}`, 0.75, { opacity: 0, ease: this.$gsap.Linear.easeNone });
            }
          }

          // create scene and set its params
          const scene = new this.$scrollmagic.Scene({
            triggerElement: '.pinContainer',
            triggerHook: window.innerWidth <= 1300 ? 0.05 : 0.2,
            duration: 2725,
          })
            .setPin('.pinContainer')
            .setTween(tl);
          // Add scene to ScrollMagic controller by emiting
          // an 'addScene' event on vm.$ksvuescr (which is our global event bus)
          this.$ksvuescr.$emit('addScene', 'pinContainerScene', scene);
        },
      },
      computed: {
        lang() {
          return this.$route.path.substring(1, 3) || localStorage.lang;
        },
      },
      destroyed() {
        // Destroy ScrollMagic when our component is removed from DOM
        // this.$ksvuescr.$emit('destroy');
      },
      created() {},

    };
</script>

<style lang="scss" scoped>
  .pinContainer {
    margin-top: -200px;
    margin-bottom: -320px;
    overflow: hidden;
    position: relative;
    @media screen and (max-width:450px) {
      margin-bottom: 0;
    }

  }
  .panel {
    /*height: 100vh;*/
    position: absolute;
    top: 0;
    left:20%;
    width: 70%;
    @media screen and (max-width:450px) {
      left: 50%;
      transform: translateX(-50%);
    }
  }
  img.main-image{
    @media screen and (min-width: 451px) and (max-width: 1600px){
      width: 90%;
    }
  }
  .right-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    .icon{
      margin-top: 117px;
      width: 15%;
      @media screen and (max-width:450px) {
        display: none;
      }
    }
    .main-image-container{
      @media screen and (max-width:450px) {
        position: relative;
      }
    }


    .title{
      margin-top: 40px;
      font-family: Roboto;
      font-size: 28px;
      font-weight: 500;
      letter-spacing: 0.6px;
      color: #4a4a4a;
      @media screen and (max-width:450px) {
        margin-top: 0;
        margin-bottom: 0;
        text-align: center;
      }
    }
    .text{
      margin-top: 30px;
      font-family: Roboto;
      font-size: 20px;
      font-weight: 300;
      line-height: 1.35;
      color: #4a4a4a;
      text-align: center;
      @media screen and (max-width:450px) {
        font-size: 12px;
        margin-top: 10px;
      }
    }
  }
  .guide{
    position: absolute;
    left: 95%;
    top: 15%;
    display: flex;
    flex-direction: column;
    height: 20%;
    justify-content: space-around;
    @media screen and (max-width:450px) {
      left: 90%;
    }
    .inner-guide{
      height: 25px;
      width: 25px;
      border-radius: 50%;
      border: 1px solid #00b2e9;
      position: relative;
    }
    .inner-guide-control{
      border-radius: 50%;
      background-color: #0fb4e7;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
    }
  }
  .guide.guide-small{
    flex-direction: row;
    left: 50%;
    transform: translateX(-50%);
    top: 75%;
    width: 50%;
  }
</style>
