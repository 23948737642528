<template>
  <v-container fluid class="header" :class="{ rtl: lang == 'ar' }">
    <h1>{{ $t("termsTitle") }}</h1>
    <p>{{ $t("termsIntroduction") }}</p>
    <h2>{{ $t("termsDefinition") }}</h2>
    <p>{{ $t("termsPoint1Paragraph1") }}</p>
    <p>{{ $t("termsPoint1Paragraph2") }}</p>
    <p>{{ $t("termsPoint1Paragraph3") }}</p>
    <p>{{ $t("termsPoint1Paragraph4") }}</p>
    <p>{{ $t("termsPoint1Paragraph5") }}</p>
    <p>{{ $t("termsPoint1Paragraph6") }}</p>
    <p>{{ $t("termsPoint1Paragraph7") }}</p>
    <h2>{{ $t("termsPoint2title") }}</h2>
    <p>{{ $t("termsPoint2Paragraph1") }}</p>
    <p>{{ $t("termsPoint2Paragraph2") }}</p>
    <p>{{ $t("termsPoint2Paragraph3") }}</p>
    <h2>{{ $t("termsPoint3Title") }}</h2>
    <p>{{ $t("termsPoint3Paragraph") }}</p>
    <h2>{{ $t("termsPoint4Title") }}</h2>
    <p>{{ $t("termsPoint4Paragraph") }}</p>
    <h2>{{ $t("termsPoint5Title") }}</h2>
    <p>{{ $t("termsPoint5Paragraph") }}</p>
    <h2>{{ $t("termsPoint6Title") }}</h2>
    <p>{{ $t("termsPoint6Paragraph") }}</p>
    <h2>{{ $t("termsPoint7Title") }}</h2>
    <p>{{ $t("termsPoint7Paragraph") }}</p>
    <h2>{{ $t("termsPoint8Title") }}</h2>
    <p>{{ $t("termsPoint8Paragraph") }}</p>
    <h2>{{ $t("termsPoint9Title") }}</h2>
    <p>{{ $t("termsPoint9Paragraph") }}</p>
    <h2>{{ $t("termsPoint10Title") }}</h2>
    <p>{{ $t("termsPoint10Paragraph") }}</p>
    <p>{{ $t("termsPoint10Paragraph2") }}</p>
    <h2>{{ $t("termsPoint11Title") }}</h2>
    <p>{{ $t("termsPoint11Paragraph") }}</p>
    <h2>{{ $t("termsPoint12Title") }}</h2>
    <p>{{ $t("termsPoint12Paragraph") }}</p>
    <h2>{{ $t("termsPoint13Title") }}</h2>
    <p>{{ $t("termsPoint13Point1Paragraph") }}</p>
    <h2>{{ $t("termsPoint14Title") }}</h2>
    <p>{{ $t("termsPoint14Paragraph") }}</p>
    <h2>{{ $t("termsPoint14Point1Title") }}</h2>
    <p>{{ $t("termsPoint14Point1Paragraph") }}</p>
    <h2>{{ $t("termsPoint14Point2Title") }}</h2>
    <p>{{ $t("termsPoint14Point2Paragraph1") }}</p>
    <p>{{ $t("termsPoint14Point2Paragraph2") }}</p>
    <p>{{ $t("termsPoint14Point2Paragraph3") }}</p>
    <h2>{{ $t("termsPoint14Point3Title") }}</h2>
    <p>{{ $t("termsPoint14Point3Point1") }}</p>
    <p>{{ $t("termsPoint14Point3Point2") }}</p>
    <p>{{ $t("termsPoint14Point3Point3") }}</p>
    <p>{{ $t("termsPoint14Point3Point4") }}</p>
    <p>{{ $t("termsPoint14Point3Point5") }}</p>
    <p>{{ $t("termsPoint14Point3Point6") }}</p>
    <h2>{{ $t("termsPoint15Title") }}</h2>
    <p>{{ $t("termsPoint15Paragraph1") }}</p>
    <h2>{{ $t("termsPoint15Point1Title") }}</h2>
    <p>{{ $t("termsPoint15Point1Point1") }}</p>
    <p>{{ $t("termsPoint15Point1Point2") }}</p>
    <p>{{ $t("termsPoint15Point1Point3") }}</p>
    <p>{{ $t("termsPoint15Point1Point4") }}</p>
    <p>{{ $t("termsPoint15Point1Point5") }}</p>
    <p>{{ $t("termsPoint15Point1Point6") }}</p>
    <p>{{ $t("termsPoint15Point1Point7") }}</p>
    <p>{{ $t("termsPoint15Point1Point8") }}</p>
    <p>{{ $t("termsPoint15Point1Point9") }}</p>
    <p>{{ $t("termsPoint15Point1Point10") }}</p>
    <p>{{ $t("termsPoint15Point1Point11") }}</p>
    <p>{{ $t("termsPoint15Point1Point12") }}</p>
    <p>{{ $t("termsPoint15Point1Point13") }}</p>
    <p>{{ $t("termsPoint15Point1Point14") }}</p>
    <p>{{ $t("termsPoint15Point1Point15") }}</p>
    <h2>{{ $t("termsPoint15Point2Title") }}</h2>
    <p>{{ $t("termsPoint15Point2Point1") }}</p>
    <p>{{ $t("termsPoint15Point2Point2") }}</p>
    <p>{{ $t("termsPoint15Point2Point3") }}</p>
    <p>{{ $t("termsPoint15Point2Point4") }}</p>
    <p>{{ $t("termsPoint15Point2Point5") }}</p>
    <p>{{ $t("termsPoint15Point2Point6") }}</p>
    <p>{{ $t("termsPoint15Point2Point7") }}</p>
    <p>{{ $t("termsPoint15Point2Point8") }}</p>
    <h2>{{ $t("termsPoint16Title") }}</h2>
    <p>{{ $t("termsPoint16Paragraph") }}</p>
    <h2>{{ $t("termsPoint17Title") }}</h2>
    <p>{{ $t("termsPoint17Paragraph1") }}</p>
    <p>{{ $t("termsPoint17Paragraph2") }}</p>
    <p>{{ $t("termsPoint17Paragraph3") }}</p>
    <p>{{ $t("termsPoint17Paragraph4") }}</p>
    <h2>{{ $t("termsPoint18Title") }}</h2>
    <p>{{ $t("termsPoint18Paragraph") }}</p>
    <h2>{{ $t("termsPoint19Title") }}</h2>
    <p>{{ $t("termsPoint19Paragraph") }}</p>
    <h2>{{ $t("termsPoint20Title") }}</h2>
    <p>{{ $t("termsPoint20Paragraph") }}</p>
  </v-container>

  <!-- <v-container v-else fluid class="header" :class="{ rtl: lang == 'ar' }">
    <h1>{{ $t("termsClientTitle") }}</h1>
    <h2>{{ $t("termsPoint1Title") }}</h2>
    <p>{{ $t("termsPoint1Paragraph1") }}</p>
    <p>{{ $t("termsPoint1Paragraph2") }}</p>
    <p>{{ $t("termsPoint1Paragraph3") }}</p>
    <p>{{ $t("termsPoint1Paragraph4") }}</p>
    <p>{{ $t("termsPoint1Paragraph5") }}</p>
    <p>{{ $t("termsPoint1Paragraph6") }}</p>
    <h2>{{ $t("termsPoint2Title") }}</h2>
    <p>{{ $t("termsPoint2Paragraph") }}</p>
    <h2>{{ $t("termsPoint3Title") }}</h2>
    <p>{{ $t("termsPoint3Paragraph") }}</p>
    <h2>{{ $t("termsPoint4Title") }}</h2>
    <p>{{ $t("termsPoint4Paragraph") }}</p>
    <h2>{{ $t("termsPoint5Title") }}</h2>
    <p>{{ $t("termsPoint5Paragraph") }}</p>
    <h2>{{ $t("termsPoint6Title") }}</h2>
    <p>{{ $t("termsPoint6Paragraph") }}</p>
    <h2>{{ $t("termsPoint7Title") }}</h2>
    <h3>{{ $t("termsPoint7Point1Title") }}</h3>
    <p>{{ $t("termsPoint7Point1Paragraph") }}</p>
    <h3>{{ $t("termsPoint7Point2Title") }}</h3>
    <p>{{ $t("termsPoint7Point2Paragraph") }}</p>
    <h2>{{ $t("termsPoint8Title") }}</h2>
    <p>{{ $t("termsPoint8Paragraph") }}</p>
    <h2>{{ $t("termsPoint9Title") }}</h2>
    <p>{{ $t("termsPoint9Paragraph") }}</p>
    <h2>{{ $t("termsPoint10Title") }}</h2>
    <p>{{ $t("termsPoint10Paragraph1") }}</p>
    <p>{{ $t("termsPoint10Paragraph2") }}</p>
    <p>{{ $t("termsPoint10Paragraph3") }}</p>
    <h3>{{ $t("termsPoint10Paragraph4Title") }}</h3>
    <p>{{ $t("termsPoint10Paragraph4") }}</p>
    <h2>{{ $t("termsPoint11Title") }}</h2>
    <p>{{ $t("termsPoint11Paragraph1") }}</p>
    <p>{{ $t("termsPoint11Paragraph2") }}</p>
    <p>{{ $t("termsPoint11Paragraph3") }}</p>
    <h2>{{ $t("termsPoint12Title") }}</h2>
    <p>{{ $t("termsPoint12Paragraph") }}</p>
    <h2>{{ $t("termsPoint13Title") }}</h2>
    <h3>{{ $t("termsPoint13Point1Title") }}</h3>
    <p>{{ $t("termsPoint13Point1Paragraph") }}</p>
    <h3>{{ $t("termsPoint13Point2Title") }}</h3>
    <p>{{ $t("termsPoint13Point2Paragraph1") }}</p>
    <p>{{ $t("termsPoint13Point2Paragraph2") }}</p>
    <p>{{ $t("termsPoint13Point2Paragraph3") }}</p>
    <h3>{{ $t("termsPoint13Point3Title") }}</h3>
    <p>{{ $t("termsPoint13Point3Paragraph") }}</p>
    <h2>{{ $t("termsPoint14Title") }}</h2>
    <p>{{ $t("termsPoint14Paragraph") }}</p>
    <h2>{{ $t("termsPoint15Title") }}</h2>
    <h3>{{ $t("termsPoint15Point1Title") }}</h3>
    <p>{{ $t("termsPoint15Point1Paragraph") }}</p>
    <h3>{{ $t("termsPoint15Point2Title") }}</h3>
    <p>{{ $t("termsPoint15Point2Paragraph1") }}</p>
    <p>{{ $t("termsPoint15Point2Paragraph2") }}</p>
    <h1>{{ $t("driversTermsTitle") }}</h1>
    <h2>{{ $t("driversTermsPoint1Title") }}</h2>
    <p>{{ $t("driversTermsPoint1Paragraph1") }}</p>
    <p>{{ $t("driversTermsPoint1Paragraph2") }}</p>
    <p>{{ $t("driversTermsPoint1Paragraph3") }}</p>
    <p>{{ $t("driversTermsPoint1Paragraph4") }}</p>
    <p>{{ $t("driversTermsPoint1Paragraph5") }}</p>
    <p>{{ $t("driversTermsPoint1Paragraph6") }}</p>
    <h2>{{ $t("driversTermsPoint2Title") }}</h2>
    <p>{{ $t("driversTermsPoint2Paragraph") }}</p>
    <h2>{{ $t("driversTermsPoint3Title") }}</h2>
    <p>{{ $t("driversTermsPoint3Paragraph") }}</p>
    <h2>{{ $t("driversTermsPoint4Title") }}</h2>
    <p>{{ $t("driversTermsPoint4Paragraph") }}</p>
    <h2>{{ $t("driversTermsPoint5Title") }}</h2>
    <p>{{ $t("driversTermsPoint5Paragraph") }}</p>
    <h2>{{ $t("driversTermsPoint6Title") }}</h2>
    <p>{{ $t("driversTermsPoint6Paragraph") }}</p>
    <h2>{{ $t("driversTermsPoint7Title") }}</h2>
    <h3>{{ $t("driversTermsPoint7Point1Title") }}</h3>
    <p>{{ $t("driversTermsPoint7Point1Paragraph") }}</p>
    <h3>{{ $t("driversTermsPoint7Point2Title") }}</h3>
    <p>{{ $t("driversTermsPoint7Point2Paragraph") }}</p>
    <h2>{{ $t("driversTermsPoint8Title") }}</h2>
    <h3>{{ $t("driversTermsPoint8Point1Title") }}</h3>
    <p>{{ $t("driversTermsPoint8Point1Paragraph") }}</p>
    <h3>{{ $t("driversTermsPoint8Point2Title") }}</h3>
    <p>{{ $t("driversTermsPoint8Point2Paragraph") }}</p>
    <h3>{{ $t("driversTermsPoint8Point3Title") }}</h3>
    <p>{{ $t("driversTermsPoint8Point3Paragraph") }}</p>
    <h3>{{ $t("driversTermsPoint8Point4Title") }}</h3>
    <p>{{ $t("driversTermsPoint8Point4Paragraph") }}</p>
    <h3>{{ $t("driversTermsPoint8Point5Title") }}</h3>
    <p>{{ $t("driversTermsPoint8Point5Paragraph") }}</p>
    <h3>{{ $t("driversTermsPoint8Point6Title") }}</h3>
    <p>{{ $t("driversTermsPoint8Point6Paragraph") }}</p>
    <h3>{{ $t("driversTermsPoint8Point7Title") }}</h3>
    <p>{{ $t("driversTermsPoint8Point7Paragraph") }}</p>
    <h2>{{ $t("driversTermsPoint9Title") }}</h2>
    <h3>{{ $t("driversTermsPoint9Point1Title") }}</h3>
    <p>{{ $t("driversTermsPoint9Point1Paragraph") }}</p>
    <h3>{{ $t("driversTermsPoint9Point2Title") }}</h3>
    <p>{{ $t("driversTermsPoint9Point2Paragraph") }}</p>
    <h3>{{ $t("driversTermsPoint9Point3Title") }}</h3>
    <p>{{ $t("driversTermsPoint9Point3Paragraph") }}</p>
    <h3>{{ $t("driversTermsPoint9Point4Title") }}</h3>
    <p>{{ $t("driversTermsPoint9Point4Paragraph") }}</p>
    <h2>{{ $t("driversTermsPoint10Title") }}</h2>
    <h3>{{ $t("driversTermsPoint10Point1Title") }}</h3>
    <p>{{ $t("driversTermsPoint10Point1Paragraph") }}</p>
    <h3>{{ $t("driversTermsPoint10Point2Title") }}</h3>
    <p>{{ $t("driversTermsPoint10Point2Paragraph") }}</p>
    <h3>{{ $t("driversTermsPoint10Point3Title") }}</h3>
    <p>{{ $t("driversTermsPoint10Point3Paragraph") }}</p>
    <h2>{{ $t("driversTermsPoint11Title") }}</h2>
    <p>{{ $t("driversTermsPoint11Paragraph") }}</p>
    <h2>{{ $t("driversTermsPoint12Title") }}</h2>
    <h3>{{ $t("driversTermsPoint12Point1Title") }}</h3>
    <p>{{ $t("driversTermsPoint12Point1Paragraph") }}</p>
    <h3>{{ $t("driversTermsPoint12Point2Title") }}</h3>
    <p>{{ $t("driversTermsPoint12Point2Paragraph") }}</p>
  </v-container> -->
</template>

<script>
export default {
  name: "Terms",
  metaInfo: {
    title: "Ego | Terms and Conditions",
  },
  computed: {
    lang() {
      return localStorage.lang || this.$route.path.substring(1, 3);
    },
  },
  mounted() {
    this.$vuetify.goTo(240, { easing: "easeInOutCubic", duration: 2000 });
  },
};
</script>

<style lang="scss" scoped>
.header {
  background-image: linear-gradient(318deg, #00caf1, #00a0e2 88%, #009ae0);
  color: #fff;
  padding: 250px;
  h1 {
    font-size: 40px;
    text-align: center;
    padding-bottom: 20px;
  }
  h2 {
    font-size: 30px;
    line-height: 40px;
  }
  h3 {
    font-size: 25px;
  }
  p {
    font-size: 20px;
  }
  &.rtl {
    direction: rtl;
  }
  @media screen and (max-width: 450px) {
    padding: 150px 10px;
  }
}
</style>
