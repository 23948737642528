<template>
  <v-container fluid class="header" :class="{ rtl: lang == 'ar' }">
    <v-layout v-reveal="{ opacity: 0, duration: 1000 }" wrap>
      <v-flex class="left-container" xs12 md6>
        <h1 class="title-1">{{ $t("ride safely") }}</h1>
        <h2 class="title-2">{{ $t("ride with ego") }}</h2>
        <p class="text">{{ $t("main header paragraph") }}</p>
        <!--<p class="text">At Ego, you can choose everything from the model of the car to the-->
        <!--gender of the driver. We make it more luxurious. We make it more-->
        <!--affordable. Your new adventure is one tap away, ride now!</p>-->
        <div class="mt-5">
          <v-layout wrap>
            <v-flex xs12 md6>
              <a :href="androidAppUrl" target="_blank" class="android-client">
                <button slot="activator" class="social-media-btn">
                  <img
                    class="icon"
                    src="../../../static/social-media/google-play.svg"
                    alt=""
                  />
                  <div class="btn-content">
                    <span class="btn-top">{{ $t("get it on") }}</span
                    ><span class="btn-bottom">{{ $t("google play") }}</span>
                  </div>
                </button>
              </a>
            </v-flex>
            <v-flex xs12 md6>
              <a :href="iosAppUrl" target="_blank" class="ios-client">
                <button class="social-media-btn">
                  <img
                    class="icon"
                    src="../../../static/social-media/apple.svg"
                    alt=""
                  />
                  <div class="btn-content">
                    <span class="btn-top">{{ $t("available on") }}</span
                    ><span class="btn-bottom">{{ $t("app store") }}</span>
                  </div>
                </button>
              </a>
            </v-flex>
          </v-layout>
        </div>
      </v-flex>
      <v-flex md6 xs12 style="position: relative">
        <img
          src="../../../static/home-page/plane-1.png"
          srcset="
            ../../../static/home-page/plane-1@2x.png 2x,
            ../../../static/home-page/plane-1@3x.png 3x
          "
          class="plane-1"
        />
        <img
          src="../../../static/home-page/plane-2.png"
          srcset="
            ../../../static/home-page/plane-2@2x.png 2x,
            ../../../static/home-page/plane-2@3x.png 3x
          "
          class="plane-2"
        />
        <!--<img src="../../../static/home-page/cloud-1.png"-->
        <!--srcset="../../../static/home-page/cloud-1@2x.png 2x,-->
        <!--../../../static/home-page/cloud-1@3x.png 3x"-->
        <!--class="cloud-1">-->
        <img
          src="../../../static/home-page/cloud-2.png"
          srcset="
            ../../../static/home-page/cloud-2@2x.png 2x,
            ../../../static/home-page/cloud-2@3x.png 3x
          "
          class="cloud-2"
        />
        <img
          src="../../../static/home-page/cloud-2.png"
          srcset="
            ../../../static/home-page/cloud-2@2x.png 2x,
            ../../../static/home-page/cloud-2@3x.png 3x
          "
          class="cloud-3"
        />
        <img
          src="../../../static/home-page/cloud-2.png"
          srcset="
            ../../../static/home-page/cloud-2@2x.png 2x,
            ../../../static/home-page/cloud-2@3x.png 3x
          "
          class="cloud-4"
        />
        <img
          src="../../../static/home-page/cloud-1.png"
          srcset="
            ../../../static/home-page/cloud-1@2x.png 2x,
            ../../../static/home-page/cloud-1@3x.png 3x
          "
          class="cloud-5"
        />
        <img
          src="../../../static/home-page/group-16.svg"
          class="header-pic"
          :class="{ rtl: lang == 'ar' }"
        />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import config from "../../config";

export default {
  name: "Header",
  data() {
    return {
      androidAppUrl: config.androidAppUrl("client"),
      iosAppUrl: config.iosAppUrl("client"),
    };
  },
  computed: {
    lang() {
      return localStorage.lang || this.$route.path.substring(1, 3);
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  padding-top: 150px;
  /*height: 1225px;*/
  padding-bottom: 480px;
  background-image: linear-gradient(318deg, #00caf1, #00a0e2 88%, #009ae0);
  clip-path: polygon(0 0, 100% 0, 100% 80%, 0% 100%);
  &.rtl {
    direction: rtl;
  }
  @media screen and (max-width: 450px) {
    padding-top: 80px;
  }
}
.header-pic {
  position: relative;
  object-fit: contain;
  top: 140px;
  right: -17%;
  width: 78%;
  &.rtl {
    right: 17%;
    @media screen and (max-width: 450px) {
      right: 0;
    }
  }
  @media screen and (max-width: 450px) {
    width: 100%;
    right: 0;
  }
}

.plane-1 {
  position: absolute;
  top: 60%;
  left: 0%;
  animation: plane-1 ease-in 10s infinite;
  z-index: 2;
  @media screen and (max-width: 450px) {
    top: 70%;
  }
}
.plane-2 {
  position: absolute;
  top: 17%;
  left: 75%;
  animation: plane-2 ease-in 10s infinite;
  animation-delay: 0.2s;
  z-index: 2;
  @media screen and (max-width: 450px) {
    top: 50%;
  }
}
.cloud-1 {
  position: absolute;
  top: 10%;
  @media screen and (max-width: 450px) {
    left: -10%;
  }
}
.cloud-2 {
  position: absolute;
  top: 25%;
  left: 20%;
}
.cloud-3 {
  position: absolute;
  top: 5%;
  left: 35%;
  opacity: 0.2;
}
.cloud-4 {
  position: absolute;
  top: 20%;
  left: 65%;
}
.cloud-5 {
  position: absolute;
  top: 10%;
  left: 83%;
}
@keyframes plane-1 {
  0% {
    opacity: 0.3;
  }
  20% {
    opacity: 1;
  }
  70% {
    opacity: 0.8;
  }
  100% {
    transform: translateX(400px) translateY(-300px);
    opacity: 0;
  }
}
@keyframes plane-2 {
  0% {
    opacity: 0.3;
  }
  20% {
    opacity: 1;
  }
  70% {
    opacity: 0.8;
  }
  100% {
    transform: translateX(-400px) translateY(400px);
    opacity: 0;
  }
}
.left-container {
  padding-top: 190px;
  padding-left: 5%;
  padding-right: 3%;
  @media screen and (max-width: 450px) {
    padding-top: 130px;
  }
}
.title-1 {
  font-family: Roboto;
  font-size: 64px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 1.4px;
  color: #ffffff;
  @media screen and (max-width: 450px) {
    font-size: 40px;
  }
}
.title-2 {
  font-family: Roboto;
  font-size: 35px;
  font-weight: 300;
  letter-spacing: 0.8px;
  color: #ffffff;
  margin-bottom: 45px;
}
.text {
  font-family: Roboto;
  font-size: 20px;
  color: #ffffff;
  @media screen and (max-width: 450px) {
    font-size: 17px;
  }
}
.social-media-btn {
  /*display: flex;*/
  /*height: 52px;*/
  text-decoration: none;
  width: 90%;
  max-width: 220px;
  position: relative;
  border-radius: 6px;
  background-color: #ffffff;
  padding: 8px 35px 8px 50px;
  box-shadow: 0 7px 20px 0 rgba(0, 0, 0, 0.19);
  font-family: Roboto;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.4px;
  color: #000;
  transition: all 0.2s;
  @media screen and (max-width: 450px) {
    width: 90%;
    font-size: 15px;
    margin-top: 10px;
  }
  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 14px 20px 0 rgba(0, 0, 0, 0.19);
  }
  &:active {
    transform: translateY(-1px);
    box-shadow: 0 7px 20px 0 rgba(0, 0, 0, 0.19);
  }
  .icon {
    width: 30px;
    position: absolute;
    left: 15px;
    top: 18px;
    @media screen and (max-width: 450px) {
      top: 12px;
    }
  }
  .btn-content {
    display: flex;
    flex-direction: column;
    text-align: left;
    .btn-top {
      font-size: 13px;
    }
  }
}
</style>
