<template>
  <v-container fluid class="header" :class="{ rtl: lang == 'ar' }">
    <v-layout align="center" style="width: 100%">
      <transition mode="out-in" name="fade">
        <v-flex
          class="left-container"
          style="
            text-align: center;
            width: 100%;
            margin-left: auto;
            margin-right: auto;
          "
          md5
          xs12
          justify-center
          v-if="step === 'start'"
          key="slide-1"
        >
          <h1 class="title-1">
            {{
              role === "client" ? $t("become a client") : $t("become a driver")
            }}
          </h1>
          <transition mode="out-in" name="fade">
            <v-flex v-if="!phoneRegistered" key="phone">
              <p v-if="role === 'driver'" class="title-2 mt-3">
                {{ $t("driver header text") }}
              </p>
              <p v-if="role === 'client'" class="title-2 mt-3">
                {{ $t("client header text") }}
              </p>
              <div class="phone-holder-reg mt-4">
                <vue-country-code
                  class="mr-3"
                  @onSelect="onSelect"
                  :preferredCountries="['sa', 'eg']"
                >
                </vue-country-code>
                <input
                  type="tel"
                  placeholder="xxxxxx"
                  class="phone-input"
                  v-model="phoneNumber"
                  @keypress.enter="verify"
                />
              </div>
              <button class="become-driver-reg mt-5" @click="verify">
                {{
                  role === "client"
                    ? $t("become a client")
                    : $t("start with ego")
                }}
              </button>
              <div class="my-4">
                <h1 class="title-3 mb-2">
                  {{
                    role === "client"
                      ? $t("downloadClientApp")
                      : $t("downloadDriverApp")
                  }}
                </h1>
                <v-layout wrap>
                  <v-flex
                    xs12
                    md3
                    :style="{
                      'text-align': 'center',
                      width: '100%',
                      'margin-left': lang === 'en' ? 'auto' : 0,
                      'margin-right': lang === 'ar' ? 'auto' : 0
                    }"
                  >
                    <a
                      :href="androidAppUrl"
                      target="_blank"
                      class="android-driver"
                    >
                      <img
                        v-if="lang === 'ar'"
                        class="android-download"
                        src="../../static/become-driver/google-play-badge.png"
                        alt=""
                      />
                      <img
                        v-if="lang === 'en'"
                        class="android-download"
                        src="../../static/become-driver/google-play-badge (en).png"
                        alt=""
                      />
                    </a>
                  </v-flex>
                  <v-flex
                    xs12
                    md3
                    :style="{
                      'text-align': 'center',
                      width: '100%',
                      'margin-right': lang === 'en' ? 'auto' : 0,
                      'margin-left': lang === 'ar' ? 'auto' : 0
                    }"
                  >
                    <a :href="iosAppUrl" target="_blank" class="ios-driver">
                      <img
                        v-if="lang === 'ar'"
                        class="ios-download"
                        src="../../static/become-driver/app-store-badge-ar.png"
                        alt=""
                      />
                      <img
                        v-if="lang === 'en'"
                        class="ios-download"
                        src="../../static/become-driver/app-store-badge-en.png"
                        alt=""
                      />
                    </a>
                  </v-flex>
                </v-layout>
              </div>
            </v-flex>

            <v-flex v-else key="password">
              <p class="text">{{ $t("registration in progress") }}</p>
              <div class="phone-holder">
                <input
                  type="password"
                  :placeholder="$t('enter password')"
                  class="phone-input"
                  v-model="password"
                  @keypress.enter="signIn"
                />
              </div>
              <button class="become-driver mt-3" @click="signIn">
                {{ $t("sign in") }}
              </button>
              <button
                :disabled="disableForgetPassword"
                class="forget-password mt-3"
                @click="showPasswordResetMethod"
              >
                {{ $t("forgetPassword") }}
              </button>
            </v-flex>
          </transition>
        </v-flex>

        <v-flex
          class="left-container column"
          style="
            text-align: center;
            width: 100%;
            margin-left: auto;
            margin-right: auto;
          "
          md5
          justify-center
          v-else-if="step === 'password reset 1'"
          key="slide-2"
        >
          <i class="flaticon flaticon-chat"></i>
          <h2 class="title-2 mb-1 text-md-center">{{ $t("confirm phone") }}</h2>
          <p class="text text-md-center">{{ $t("enter phone code") }}</p>
          <v-layout
            align-center
            justify-space-around
            class="otp-holder"
            style="direction: ltr"
          >
            <input
              type="number"
              min="0"
              max="9"
              class="confirmation-input"
              v-model.number="number1"
              @keydown="checkInput('number1', 'number1', null, $event)"
              @keyup="next('input2', $event)"
              ref="input1"
            />
            <input
              type="number"
              min="0"
              max="9"
              class="confirmation-input"
              v-model.number="number2"
              @keydown="checkInput('number2', 'number1', 'input1', $event)"
              @keyup="next('input3', $event)"
              ref="input2"
            />
            <input
              type="number"
              min="0"
              max="9"
              class="confirmation-input"
              v-model.number="number3"
              @keydown="checkInput('number3', 'number2', 'input2', $event)"
              @keyup="next('input4', $event)"
              ref="input3"
            />
            <input
              type="number"
              min="0"
              max="9"
              class="confirmation-input"
              v-model.number="number4"
              @keydown="checkInput('number4', 'number3', 'input3', $event)"
              @keyup="next('confirm', $event)"
              ref="input4"
            />
          </v-layout>
          <button
            class="become-driver mb-2 mt-2"
            ref="confirm"
            @click="verifyOtpReset"
          >
            {{ $t("confirm") }}
          </button>
          <button class="become-driver red mb-2" @click="step = 'start'">
            {{ $t("cancel") }}
          </button>
          <button
            class="become-driver green"
            :disabled="resendOtpDisabled"
            @click="showPasswordResetMethod"
          >
            {{ $t("resendOtp") }} {{ countDownText }}
          </button>
        </v-flex>
        <v-flex
          class="left-container cloumn"
          style="
            text-align: center;
            width: 100%;
            margin-left: auto;
            margin-right: auto;
          "
          md5
          justify-center
          v-else-if="step === 'password reset 2'"
          key="slide-3"
        >
          <p class="title-2">{{ $t("resetPassword") }}</p>
          <div class="phone-holder">
            <input
              type="password"
              :placeholder="$t('enter password')"
              class="phone-input"
              v-model="passwordReset"
              @keypress.enter="signIn"
            />
          </div>

          <div class="phone-holder">
            <input
              type="password"
              :placeholder="$t('confirm password')"
              class="phone-input"
              v-model="passwordResetConfirm"
              @keypress.enter="signIn"
            />
          </div>
          <p class="error-message">{{ confirmPasswordError }}</p>

          <button class="become-driver" @click="resetPassword">
            {{ $t("resetPassword") }}
          </button>
        </v-flex>

        <v-flex
          class="left-container cloumn"
          style="
            text-align: center;
            width: 100%;
            margin-left: auto;
            margin-right: auto;
          "
          md5
          justify-center
          v-else-if="step === 'password reset 3'"
          key="slide-4"
        >
          <img
            src="../../static/contact-us/group-3.svg"
            alt=""
            style="transform: translateX(80%)"
            :class="{ arabicIcon: lang === 'ar' }"
          />
          <p class="title-2" style="text-align: center">
            {{ $t("passwordUpdated") }}
          </p>
          <button
            class="become-driver"
            style="display: block; margin: 0 auto"
            @click="step = 'start'"
          >
            {{ $t("ok") }}
          </button>
        </v-flex>
        <v-flex
          class="left-container column"
          md5
          justify-center
          style="
            text-align: center;
            width: 100%;
            margin-left: auto;
            margin-right: auto;
          "
          v-else-if="step === 'otp'"
          key="slide-4-a"
        >
          <i class="flaticon flaticon-chat"></i>
          <h2 class="title-2 mb-1 text-md-center">{{ $t("confirm phone") }}</h2>
          <p class="text text-md-center">{{ $t("enter phone code") }}</p>
          <v-layout
            align-center
            justify-space-around
            class="otp-holder"
            style="direction: ltr"
          >
            <input
              type="number"
              min="0"
              max="9"
              class="confirmation-input"
              v-model.number="number1"
              @keydown="checkInput('number1', 'number1', null, $event)"
              @keyup="next('input2', $event)"
              ref="input1"
            />
            <input
              type="number"
              min="0"
              max="9"
              class="confirmation-input"
              v-model.number="number2"
              @keydown="checkInput('number2', 'number1', 'input1', $event)"
              @keyup="next('input3', $event)"
              ref="input2"
            />
            <input
              type="number"
              min="0"
              max="9"
              class="confirmation-input"
              v-model.number="number3"
              @keydown="checkInput('number3', 'number2', 'input2', $event)"
              @keyup="next('input4', $event)"
              ref="input3"
            />
            <input
              type="number"
              min="0"
              max="9"
              class="confirmation-input"
              v-model.number="number4"
              @keydown="checkInput('number4', 'number3', 'input3', $event)"
              @keyup="next('confirm', $event)"
              ref="input4"
            />
          </v-layout>
          <button
            class="become-driver mb-2 mt-2"
            ref="confirm"
            @click="verifyOtp"
          >
            {{ $t("confirm") }}
          </button>
          <button class="become-driver red mb-2" @click="step = 'start'">
            {{ $t("cancel") }}
          </button>
          <button
            class="become-driver green"
            :disabled="resendOtpDisabled"
            @click="verify('second')"
          >
            {{ $t("resendOtp") }} {{ countDownText }}
          </button>
        </v-flex>
      </transition>
    </v-layout>
    <v-snackbar
      :timeout="3000"
      v-model="requestErrorPresent"
      style="left: 0; top: 70%"
    >
      {{ requestErrorMessage }}
      <v-btn flat color="pink" @click.native="requestErrorPresent = false">{{
        $t("close")
      }}</v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import "../../static/become-driver/font/flaticon2.css";

import backend from "../backend";
import config from "../config";

export default {
  name: "AccountGate",
  props: {
    role: { required: true, type: String }
  },
  data() {
    const { role } = this;

    return {
      disableForgetPassword: false,
      selectedCountry: "SA",
      dialCode: "+966",
      passwordReset: "",
      passwordResetConfirm: "",
      step: "start",
      number1: null,
      number2: null,
      number3: null,
      number4: null,
      phoneNumber: null,
      password: "",
      phoneRegistered: false,
      resendOtpDisabled: true,
      otpExpiry: 10,
      countDownMinutes: 0,
      countdownSeconds: 30,
      interval: null,

      requestErrorPresent: false,
      requestErrorMessage: "",

      androidAppUrl: config.androidAppUrl(role),
      iosAppUrl: config.iosAppUrl(role)
    };
  },

  methods: {
    onSelect({ name, iso2, dialCode }) {
      this.selectedCountry = iso2;
      this.dialCode = dialCode;
    },
    async resetPassword() {
      const { role } = this;
      const { lang, passwordReset, passwordResetConfirm } = this;

      try {
        await backend.resetPassword(
          lang,
          role,
          passwordReset,
          passwordResetConfirm
        );

        this.step = "password reset 3";
      } catch (err) {
        this.handleRequestError(err);
      }
    },
    async showPasswordResetMethod() {
      const { role } = this;
      const { dialCode, lang, phoneNumber: phone } = this;

      this.disableForgetPassword = true;
      setTimeout(() => {
        this.disableForgetPassword = false;
      }, 2000);
      this.step = "password reset 1";

      try {
        const { expiry } = await backend.sendOtp(
          lang,
          role,
          "reset-password",
          phone,
          dialCode
        );

        setTimeout(() => this.$refs.input1.focus(), 700);
        this.otpExpiry = Math.round((expiry - new Date().getTime()) / 60000);
        this.countDownMinutes = 1;
        this.countdownSeconds = 30;
        this.resendOtpDisabled = true;
        clearInterval(this.interval);
        this.countDown();
      } catch (err) {
        this.handleRequestError(err);
      }
    },
    handleRequestError(err) {
      this.requestErrorMessage = err.message;
      this.requestErrorPresent = true;
    },
    async verify(second = null) {
      const { role } = this;
      const { dialCode, lang, phoneNumber: phone } = this;

      try {
        const { expiry, phoneRegistered } = await backend.sendOtp(
          lang,
          role,
          "register",
          phone,
          dialCode
        );

        if (phoneRegistered) {
          this.phoneRegistered = phoneRegistered;
          return;
        }

        this.step = "otp";
        setTimeout(() => this.$refs.input1.focus(), 700);
        this.otpExpiry = Math.round((expiry - new Date().getTime()) / 60000);
        this.countDownMinutes = 1;
        this.countdownSeconds = 30;
        this.resendOtpDisabled = true;
        clearInterval(this.interval);
        this.countDown();
      } catch (err) {
        this.handleRequestError(err);
      }
    },
    countDown() {
      let minutes = this.countDownMinutes;
      let seconds = this.countdownSeconds;
      this.interval = setInterval(() => {
        if (seconds === 0) {
          minutes -= 1;
          seconds = 59;
        } else {
          seconds -= 1;
        }
        if (minutes === 0 && seconds === 0) {
          clearInterval(this.interval);
          this.resendOtpDisabled = false;
        }
        this.countDownMinutes = minutes;
        this.countdownSeconds = seconds;
      }, 1000);
    },
    async verifyOtp() {
      const { role } = this;

      const {
        lang,
        dialCode,

        phoneNumber: phone,

        number1,
        number2,
        number3,
        number4
      } = this;

      const otp = `${number1}${number2}${number3}${number4}`;

      try {
        const phoneSession = await backend.verifyOtp(
          lang,
          role,
          "register",
          phone,
          otp,
          dialCode
        );

        this.$router.push(`/${this.lang}/${role}-register/steps`);
        localStorage.isSignedIn = true;
        localStorage.accessToken = phoneSession.accessToken;

        //mixpanel integration
        mixpanel.people.set({ $phone: phoneSession.intlPhone });
        mixpanel.people.set({ "Phone Number": phoneSession.intlPhone });
        mixpanel.people.set({ "User Type": role });
        mixpanel.track("Registration Start", {
          $phone: phone,
          "User Type": role
        });

        if (localStorage.phone !== phoneSession.phone.toString()) {
          localStorage.country = "";
          localStorage.email = "";
          localStorage.firstName = "";
          localStorage.lastName = "";
          localStorage.nationality = "";
          localStorage.ssn = "";
          localStorage.isOwner = "";
        }

        localStorage.phone = phoneSession.phone;
        localStorage.country = phoneSession.suggestedCountry;
        localStorage.step = 0;
        localStorage.dialCode = phoneSession.dialCode;
      } catch (err) {
        this.handleRequestError(err);
      }
    },
    async verifyOtpReset() {
      const { role } = this;

      const {
        lang,
        dialCode,
        phoneNumber: phone,
        number1,
        number2,
        number3,
        number4
      } = this;

      const otp = `${number1}${number2}${number3}${number4}`;

      try {
        await backend.verifyOtp(
          lang,
          role,
          "reset-password",
          phone,
          otp,
          dialCode
        );

        this.step = "password reset 2";
      } catch (err) {
        this.handleRequestError(err);
      }
    },
    next(ref, event) {
      const numberKey = Number(event.key);
      const numberTarget = Number(event.target.value);
      if (isNaN(numberTarget)) {
        // eslint-disable-next-line no-param-reassign
        event.target.value = "";
      }
      if (!isNaN(numberKey)) {
        this.$refs[ref].focus();
      }
    },
    async signIn() {
      const { role } = this;
      const { dialCode, lang, password, phoneNumber } = this;

      try {
        const { session, user } = await backend.login(
          lang,
          role,
          phoneNumber,
          password,
          dialCode
        );
        //mixpanel Integration
        if (role === "driver") {
          console.log("userId", user.id);
          mixpanel.identify(user.id);
        }

        this.$router.push(`/${lang}/${role}-register/steps`);
        localStorage.isSignedIn = true;
        localStorage.accessToken = session.accessToken;

        if (localStorage.phone !== user.phone.toString()) {
          localStorage.country = "";
          localStorage.email = "";
          localStorage.firstName = "";
          localStorage.lastName = "";
          localStorage.nationality = "";
          localStorage.ssn = "";
          localStorage.isOwner = "";
        }
        localStorage.phone = user.phone;

        if (role === "client") {
          localStorage.clientId = user.id;
        } else {
          localStorage.driverId = user.id;
        }

        if (role !== "driver") {
          return;
        }

        switch (user.currentRegistrationStep) {
          case "uploads":
            localStorage.step = 1;
            break;
          case "inspection":
            localStorage.step = 2;
            break;
          default:
            localStorage.step = 0;
        }
      } catch (err) {
        this.handleRequestError(err);
      }
    },
    checkInput(input, nextModel, nextInput, event) {
      if (event.keyCode !== 8 && event.target.value.length > 0) {
        event.preventDefault();
      }
      if (event.keyCode === 8) {
        event.preventDefault();
        if (this[input] || this[input] === 0) {
          this[input] = "";
        } else {
          this[nextModel] = "";
          this.$refs[nextInput].focus();
        }
      }
    }
  },
  computed: {
    confirmPasswordError() {
      if (this.passwordReset !== this.passwordResetConfirm) {
        return this.$t("passwordMatch");
      }
      return "";
    },
    lang() {
      return localStorage.lang || this.$route.path.substring(1, 3);
    },
    countDownText() {
      let seconds = this.countdownSeconds;
      if (seconds < 10) {
        seconds = `0${seconds}`;
      }
      let minutes = this.countDownMinutes;
      if (minutes < 10) {
        minutes = `0${minutes}`;
      }
      return `${minutes}:${seconds}`;
    },
    driverYoutubeUrl() {
      const { lang } = this;

      return lang === "ar"
        ? "https://www.youtube-nocookie.com/embed/NZ8rj4f1p0g"
        : "https://www.youtube-nocookie.com/embed/gEyddMN08_4";
    }
  }
};
</script>

<style lang="scss" scoped>
.header {
  padding-top: 150px;
  padding-bottom: 480px;
  background-image: linear-gradient(42deg, #007b94, #016f9e 88%, #006492);
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  height: fit-content;
  &.rtl {
    direction: rtl;
  }
  @media screen and (max-width: 450px) {
    padding-top: 50px;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    // height: 1400px;
    padding-bottom: 50px;
  }
}

.header-pic {
  position: relative;
  object-fit: contain;
  top: 70px;
  right: -17%;
  /*width: 78%*/
  animation: rotate 300s infinite linear alternate-reverse;
  &.rtl {
    right: 17%;
    top: 150px;
    @media screen and (max-width: 450px) {
      right: 0;
      top: 0;
      width: 100%;
    }
  }
  @media screen and (max-width: 450px) {
    right: 0;
    top: 0;
    width: 100%;
  }
}
.header-pic-location {
  position: absolute;
  top: 15%;
  left: 45%;
  animation: jump 5s infinite;
  &.rtl {
    top: 30%;
    left: 42%;
    @media screen and (max-width: 450px) {
      width: 10%;
      top: 15%;
      left: 45%;
    }
  }
  @media screen and (max-width: 450px) {
    width: 10%;
    top: 15%;
    left: 45%;
  }
}

.android-download {
  width: 100%;
}
.ios-download {
  width: 100%;
}

.left-container {
  padding-top: 190px;
  padding-left: 5%;
  padding-right: 3%;
}
.column {
  display: flex;
  flex-direction: column;
}
.flaticon {
  text-align: center;
  color: #fff;
  &:before {
    font-size: 60px;
  }
}
.confirmation-input {
  width: 62px;
  height: 47px;
  border-radius: 6px;
  background-color: #ffffff;
  border: solid 1px rgba(168, 168, 168, 0.69);
  text-align: center;
}
.phone-holder {
  display: flex;
  align-items: center;
  border-radius: 8px;
  background-color: #ffffff;
  padding: 20px;
  margin-bottom: 20px;
  // width: 370px;
  /*width: 80%;*/
  .icon {
    width: 30px;
    margin-right: 10px;
  }
  .code-holder {
    opacity: 0.4;
    font-family: Roboto;
    font-size: 16px;
    color: #9b9b9b;
    margin-right: 10px;
  }
  .form-divider {
    width: 1px;
    flex: 1px 0 0;
    height: 19px;
    background-color: #979797;
    margin-right: 10px;
  }
  .phone-input {
    outline: none;
    margin-right: 10px;
  }
  @media screen and (max-width: 450px) {
    width: 100%;
    .code-holder {
      font-size: 12px;
    }
    .icon {
      width: 25px;
    }
    .phone-input {
      font-size: 20px;
    }
  }
}
.phone-holder-reg {
  direction: ltr;
  display: flex;
  align-items: center;
  border-radius: 8px;
  background-color: #ffffff;
  padding: 20px;
  margin: auto;
  width: 100%;
  /*width: 80%;*/
  .icon {
    width: 30px;
    margin-right: 10px;
  }
  .code-holder {
    opacity: 0.4;
    font-family: Roboto;
    font-size: 16px;
    color: #9b9b9b;
    margin-right: 10px;
  }
  .form-divider {
    width: 1px;
    flex: 1px 0 0;
    height: 19px;
    background-color: #979797;
    margin-right: 10px;
  }
  .phone-input {
    outline: none;
    margin-right: 10px;
    width: 100%;
  }
  @media screen and (max-width: 450px) {
    width: 100%;
    .code-holder {
      font-size: 12px;
    }
    .icon {
      width: 25px;
    }
    .phone-input {
      font-size: 20px;
    }
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.title-1 {
  font-family: Roboto;
  font-size: 64px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 1.4px;
  color: #ffffff;
  @media screen and (max-width: 450px) {
    font-size: 30px;
    padding-bottom: 10px;
  }
}
.title-2 {
  font-family: Roboto;
  font-size: 30px;
  font-weight: 300;
  letter-spacing: 0.8px;
  color: #ffffff;
  margin-bottom: 45px;
  @media screen and (max-width: 450px) {
    font-size: 25px;
    /*text-align: center;*/
  }
}
.title-3 {
  font-family: Roboto;
  font-size: 25px;
  font-weight: 300;
  letter-spacing: 0.8px;
  color: #ffffff;
  margin-bottom: 45px;
  @media screen and (max-width: 450px) {
    font-size: 20px;
    /*text-align: center;*/
  }
}
.text {
  font-family: Roboto;
  letter-spacing: 1px;
  font-weight: 400;
  line-height: 50px;
  font-size: 35px;
  color: #ffffff;
  @media screen and (max-width: 450px) {
    font-size: 17px;
  }
}

.become-driver {
  min-width: 370px;
  padding: 20px 110px;
  border-radius: 8px;
  background-color: #1d4d5c;
  box-shadow: 0 4px 0 0 #068bc6;
  font-family: Roboto;
  font-size: 23px;
  color: #f2f2f2;
  transition: background-color 0.5s;
  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 14px 20px 0 rgba(0, 0, 0, 0.19);
  }
  &:focus {
    background-color: #22a68d;
    transform: translateY(-3px);
    box-shadow: 0 14px 20px 0 rgba(0, 0, 0, 0.19);
  }
  &:active {
    transform: translateY(-1px);
    box-shadow: 0 7px 20px 0 rgba(0, 0, 0, 0.19);
  }
  &[disabled] {
    background-color: #777 !important;
    transform: none;
    box-shadow: none;
  }
  @media screen and (max-width: 450px) {
    min-width: 100%;
    padding: 20px 3px;
  }
}
.become-driver-reg {
  width: 100%;
  padding: 10px 80px;
  border-radius: 8px;
  background-color: #1d4d5c;
  font-family: Roboto;
  font-size: 23px;
  color: #f2f2f2;
  transition: background-color 0.5s;
  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 14px 20px 0 rgba(0, 0, 0, 0.19);
  }
  &:focus {
    background-color: #22a68d;
    transform: translateY(-3px);
    box-shadow: 0 14px 20px 0 rgba(0, 0, 0, 0.19);
  }
  &:active {
    transform: translateY(-1px);
    box-shadow: 0 7px 20px 0 rgba(0, 0, 0, 0.19);
  }
  &[disabled] {
    background-color: #777 !important;
    transform: none;
    box-shadow: none;
  }
  @media screen and (max-width: 450px) {
    min-width: 100%;
    padding: 10px 3px;
  }
}
.otp-holder {
  @media screen and (max-width: 450px) {
    margin-bottom: 20px;
  }
}
.fade-enter-active {
  animation: fade 0.5s;
}
.fade-leave-active {
  animation: fade 0.5s reverse;
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes rotate {
  0% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(3600deg);
  }
}
@keyframes jump {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(50px);
  }
  100% {
    transform: translateY(0);
  }
}
.forget-password {
  text-decoration: none;
  display: block;
  font-size: 20px;
  padding-top: 10px;
  color: white;
  transition: transform 0.5s;
  cursor: pointer;
  // position: absolute;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  &:hover {
    /*font-size: 25px;*/
    transform: scale(1.2);
  }
}
.arabicIcon {
  transform: translateX(-90%) !important;
}

.social-media-btn {
  /*display: flex;*/
  /*height: 52px;*/
  text-decoration: none;
  width: 90%;
  max-width: 220px;
  position: relative;
  border-radius: 6px;
  background-color: #ffffff;
  padding: 8px 35px 8px 50px;
  box-shadow: 0 7px 20px 0 rgba(0, 0, 0, 0.19);
  font-family: Roboto;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.4px;
  color: #000;
  transition: all 0.2s;
  @media screen and (max-width: 450px) {
    width: 90%;
    font-size: 15px;
    margin-top: 10px;
  }
  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 14px 20px 0 rgba(0, 0, 0, 0.19);
  }
  &:active {
    transform: translateY(-1px);
    box-shadow: 0 7px 20px 0 rgba(0, 0, 0, 0.19);
  }
  .icon {
    width: 30px;
    position: absolute;
    left: 15px;
    top: 18px;
    @media screen and (max-width: 450px) {
      top: 12px;
    }
  }
  .btn-content {
    display: flex;
    flex-direction: column;
    text-align: left;
    .btn-top {
      font-size: 13px;
    }
  }
}
</style>
