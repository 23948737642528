import Vue from "vue";
import Router from "vue-router";
import Meta from "vue-meta";
import HomePage from "../components/home-page/HomePage";
import BecomeClient from "../components/BecomeClient";
import BecomeDriver from "../components/become-a-driver/BecomeDriver";
import ResetPasswordByToken from "../components/ResetPasswordByToken";
import ClientRegistrationSteps from "../components/ClientRegistrationSteps";
import DriverRegistrationSteps from "../components/become-a-driver/Steps";
import Terms from "../components/Terms";
import Privacy from "../components/Privacy";

Vue.use(Router);
Vue.use(Meta, {
  title: "Ego"
});

if (!localStorage.lang) {
  localStorage.lang = navigator.language.slice(0, 2);
}

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/:lang/client-register",
      component: BecomeClient,
      props: route => ({ referredBy: route.query.referredBy })
    },
    {
      path: "/:lang/client-register/steps",
      component: ClientRegistrationSteps
    },
    {
      path: "/:lang/driver-register",
      component: BecomeDriver,
      props: route => ({ referredBy: route.query.referredBy })
    },
    {
      path: "/:lang/reset-user-password",
      component: ResetPasswordByToken,
      props: route => ({ token: route.query.token, role: route.query.role })
    },
    {
      path: "/:lang/driver-register/steps",
      component: DriverRegistrationSteps
    },
    {
      path: "/:lang/privacy",
      component: Privacy
    },
    {
      path: "/:lang/terms",
      component: Terms
    },
    {
      path: "/",
      component: HomePage
    },
    {
      path: "/:lang/",
      component: HomePage
    }
  ]
});
