<template>
  <v-app>
    <Loader :isShow="showLoader.val" />
    <v-navigation-drawer
      v-model="drawer"
      fixed
      temporary
      dark
      class="drawer"
      width="500"
      touchless
    >
      <v-list class="pa-1">
        <v-list-tile avatar>
          <v-list-tile-avatar>
            <img src="../static/navbar/main-logo.svg" />
          </v-list-tile-avatar>
          <v-list-tile-content>
            {{ $t("ego") }}
          </v-list-tile-content>
          <v-icon style="float: right" @click="drawer = false">close</v-icon>
        </v-list-tile>
      </v-list>
      <v-list class="pt-0">
        <v-divider></v-divider>
        <!--<v-list-tile v-for="item in items" :key="item.title" @click="">-->
        <!--<v-list-tile-action>-->
        <!--<v-icon>{{ item.icon }}</v-icon>-->
        <!--</v-list-tile-action>-->
        <!--<v-list-tile-content>-->
        <!--<v-list-tile-title>{{ item.title }}</v-list-tile-title>-->
        <!--</v-list-tile-content>-->
        <!--</v-list-tile>-->
        <v-list-tile>
          <v-list-tile-content>
            <router-link
              :to="`/${lang}/driver-register`"
              class="language-selector"
            >
              {{ $t("become a driver") }}
            </router-link>
          </v-list-tile-content>
        </v-list-tile>
        <v-divider></v-divider>
        <v-list-tile>
          <v-list-tile-content>
            <a href="mailto:care@ego-now.com" class="language-selector">
              {{ $t("contact us") }}
            </a>
          </v-list-tile-content>
        </v-list-tile>
        <v-divider></v-divider>
        <v-list-tile>
          <v-list-tile-content>
            <router-link :to="`/${lang}/privacy`" class="language-selector">
              {{ $t("privacyTitle") }}
            </router-link>
          </v-list-tile-content>
        </v-list-tile>
        <v-divider></v-divider>
        <v-list-tile>
          <v-list-tile-content>
            <router-link :to="`/${lang}/terms`" class="language-selector">
              {{ $t("termsTitle") }}
            </router-link>
          </v-list-tile-content>
        </v-list-tile>
        <v-divider></v-divider>
        <v-list-tile v-if="lang === 'ar'">
          <v-list-tile-content>
            <router-link
              to="/en"
              class="language-selector"
              v-if="lang === 'ar'"
              @click="changeLang('en')"
            >
              English
            </router-link>
          </v-list-tile-content>
        </v-list-tile>
        <v-list-tile>
          <v-list-tile-content>
            <router-link
              to="/ar"
              class="language-selector"
              v-if="lang === 'en'"
              @click="changeLang('ar')"
            >
              عربي
            </router-link>
          </v-list-tile-content>
        </v-list-tile>
      </v-list>
    </v-navigation-drawer>
    <app-navbar
      @drawerAction="drawer ? (drawer = false) : (drawer = true)"
    ></app-navbar>
    <v-content>
      <transition name="slide" mode="out-in">
        <router-view style="margin-top: -210px" />
      </transition>
      <router-view name="alal"></router-view>
    </v-content>
    <app-footer></app-footer>
    <div class="bg-after mt-2"></div>
    <!-- <div class="btn">CLOSE</div> -->
  </v-app>
</template>

<script>
/* eslint-disable no-undef */

import AppNavbar from "./components/AppNavbar";
import AppFooter from "./components/AppFooter";
import store from "./vuex/store";
import "../static/font/Geeza.css";
import Loader from "./components/Loader.vue";

export default {
  store,
  name: "App",
  components: {
    AppNavbar,
    AppFooter,
    Loader
  },
  data() {
    return {
      drawer: false,
      showLoader: {
        val: false
      }
    };
  },
  provide() {
    return {
      isShowLoader: this.showLoader
    };
  },
  methods: {
    changeLang(la) {
      localStorage.lang = la;
    }
  },
  computed: {
    lang() {
      return localStorage.lang || this.$route.path.substring(1, 3);
    }
  },
  created() {
    console.log("test_sep");
    const viewport = document.createElement("meta");
    viewport.name = "viewport";
    viewport.content =
      "width=device-width, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, user-scalable=no, target-densityDpi=device-dpi";
    const googleAnalyticsScript = document.createElement("script");
    googleAnalyticsScript.setAttribute("async", "");
    googleAnalyticsScript.src =
      "https://www.googletagmanager.com/gtag/js?id=UA-124036967-1";
    const favicon = document.createElement("link");
    favicon.rel = "shortcut icon";
    favicon.type = "image/png";
    favicon.href = "/static/favicon.png";
    document.getElementsByTagName("head")[0].appendChild(viewport);
    document.getElementsByTagName("head")[0].appendChild(googleAnalyticsScript);
    document.getElementsByTagName("head")[0].appendChild(favicon);
    window.dataLayer = window.dataLayer || [];
    // eslint-disable-next-line prefer-rest-params
    function gtag() {
      dataLayer.push(arguments);
    }
    gtag("js", new Date());

    gtag("config", "UA-124036967-1");

    //

    const descEl = document.querySelector('head meta[name="description"]');

    descEl.setAttribute(
      "content",
      localStorage.lang === "ar"
        ? "ايجو هي شركة نقل مع تطبيق يسمح للركاب بطلب  سيارة بغرض توصيلهم مشاويرهم بطريقة آمنه وأسعار مناسبه من قبل السائقين دون الحاجة للإنتظار"
        : "Ego is a transportation company with an app that allows passengers to order a car for purpose of picking them up by the drivers without having to wait"
    );
  }
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css?family=Cairo:400,700&subset=arabic");

.drawer {
  background-color: #00acfa !important;
  max-height: 100vh !important;
}

/*@media screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape) {*/
/*html {*/
/*display: none;*/
/*}*/
/*}*/

body.rtl {
  .text {
    font-family: "Cairo", sans-serif !important;
    letter-spacing: 0;
  }
  textarea:focus,
  input:focus,
  button:focus {
    outline: none !important;
  }
  .list__tile__title {
    text-align: right;
  }

  .toolbar__title {
    margin-right: 16px;
  }
  .input-group--text-field-box:not(.input-group--textarea):not(.input-group--multi-line)
    label {
    top: 10px;
  }
  .input-group--text-field label {
    position: absolute;
    right: 0;
    padding-top: 5px;
    /*top: 0;*/
  }

  .input-group label {
    text-align: right;
    -webkit-transform-origin: top right;
    transform-origin: top right;
    padding-right: 16px;
  }
  .input-group.input-group--selection-controls label {
    right: 32px;
    left: auto;
  }
  .input-group.input-group--selection-controls .icon--selection-control {
    left: auto;
    right: 0;
  }
  .input-group--selection-controls__ripple {
    -webkit-transform: translate3d(12px, -52%, 0);
    transform: translate3d(12px, -52%, 0);
    left: auto;
    right: 0;
  }

  .input-group__selections__comma {
    left: -10px;
  }
}

.input-group__selections__comma {
  /*top: 10px ;*/
  position: relative;
  left: 10px;
}

/*.input-group__selections {*/
/*border: solid 1px currentColor;*/
/*border-radius: 6px;*/

/*}*/

.input-group--text-field-box:not(.input-group--textarea):not(.input-group--multi-line)
  label {
  top: 10px;
  left: 10px;
}

// .input-group.input-group--selection-controls label{
//   /*right: 32px;*/
// }
.input-group.input-group--select.input-group--autocomplete label {
  top: 12px;
  left: 5px;
}
body {
  overflow: hidden;
}
.input-group label {
  width: 100%;
  font-size: 15px;
  @media screen and (max-width: 450px) {
    font-size: 11px;
    padding-left: 10px !important;
  }
  @media screen and (min-width: 451px) and (max-width: 1600px) {
    font-size: 11px;
    padding-left: 10px !important;
  }
}
.input-group.input-group--text-field {
  background-color: #fff !important;
  border-radius: 6px;
  input {
    height: 100%;
    padding-top: 20px;
  }
  .input-group__details {
    position: absolute;
    top: 50px;
    /*right: 1%;*/
    &::before {
      display: none !important;
    }
    &::after {
      display: none !important;
    }
  }
}

input.input-group--select__autocomplete {
  padding: 0 10px;
  height: 50px !important;
}

.input-group.input-group--error .input-group__messages {
  font-size: 10px;
}

.error-message {
  margin-left: 32px;
  color: red;
}
.input-group.input-group--text-field.input-group--select {
  padding-top: 0;
  border-radius: 6px;
  background-color: white;
  border: solid 1px currentColor;
  box-shadow: none;
  /*margin-right: 30px;*/
  width: 90%;
  margin-bottom: 20px;
}
.input-group.input-group--text-field.input-group--text-field-box {
  padding-top: 0;
  border-radius: 6px;
  background-color: transparent;
  border: solid 1px currentColor;
  box-shadow: none;
  /*margin-right: 30px;*/
  width: 90%;
  margin-bottom: 20px;
}
/*.input-group.input-group--append-icon label{*/
/*width: 89%;*/
/*font-size: 1px;*/
/*}*/

.bg-after {
  min-height: 120px;
  object-fit: contain;
  background: url("../static/footer/group-10.svg");
  @media screen and (max-width: 450px) {
    background: url("../static/footer/group-10-copy.svg");
    min-height: 138px;
  }
}
.slide-enter-active,
.slide-leave-active {
  transition: all 1.5s;
}
.slide-enter,
.slide-leave-to {
  opacity: 0;
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .slide-enter,
  .slide-leave-to {
    transform: translateY(100vh);
    opacity: 0;
  }
}

img.main-image {
  @media screen and (max-width: 450px) {
    height: 50vh;
    margin: 0 auto;
  }
}
.language-selector {
  color: #fff;
  text-decoration: none;
}

.menu__content--select {
  transform: translateY(24px);
}
</style>
