<template>
  <v-container fluid class="navbar px-0">
    <router-link :to="`/${lang}`">
      <img src="../../static/navbar/Ego_logo.svg" class="logo ml-5 mt-3" />
    </router-link>
    <v-toolbar-side-icon
      class="hidden-sm-and-up"
      @click="controlDrawer"
      style="float: right"
    ></v-toolbar-side-icon>
    <router-link
      to="/en"
      class="language-selector mr-5"
      v-if="lang === 'ar'"
      @click="changeLang('en')"
    >
      English
    </router-link>
    <router-link
      to="/ar"
      class="language-selector mr-5"
      v-if="lang === 'en'"
      @click="changeLang('ar')"
    >
      عربي
    </router-link>
    <v-layout class="links mt-4 mr-5">
      <a
        class="navbar-btn"
        :class="{ 'move-left': moveLeft }"
        @click="goToStory"
        >{{ $t("why ego") }}</a
      >
      <div
        class="navbar-divider mx-4"
        :class="{ 'move-left': moveLeft, 'move-right': moveRight }"
      ></div>
      <a
        href="mailto:care@ego-now.com"
        class="navbar-btn mr-5"
        :class="{ 'move-right': moveRight }"
        @mouseover="moveLeft = true"
        @mouseleave="moveLeft = false"
        >{{ $t("help") }}</a
      >
      <router-link :to="`/${lang}/driver-register`" class="navbar-btn-link">{{
        $t("become a driver")
      }}</router-link>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: "Navbar",
  data() {
    return {
      moveRight: false,
      moveLeft: false,
      drawer: false
    };
  },
  methods: {
    goToStory() {
      if (
        this.$route.path === "/ar" ||
        this.$route.path === "/en" ||
        this.$route.path === ""
      ) {
        this.$vuetify.goTo(4569, { easing: "easeInOutCubic", duration: 2000 });
      } else {
        this.$router.push(`/${this.lang}`, () => {
          setTimeout(
            () =>
              this.$vuetify.goTo(4569, {
                easing: "easeInOutCubic",
                duration: 2000
              }),
            3500
          );
        });
      }
    },
    changeLang(lang) {
      localStorage.lang = lang;
    },
    controlDrawer() {
      this.$emit("drawerAction");
    }
  },
  computed: {
    lang() {
      return this.$route.path.substring(1, 3) || localStorage.lang;
    }
  }
};
</script>

<style lang="scss" scoped>
.navbar {
  z-index: 1;
  min-height: 210px;
  /*background-image: linear-gradient(281deg, #00caf1, #00a0e2 88%, #009ae0);*/
  /*clip-path: polygon(0 0, 100% 0, 100% 56%, 0% 100%);*/
  /*@media screen and (max-width: 450px){*/
  /*clip-path: polygon(0 0, 100% 0, 100% 40%, 0% 70%);*/
  /*clip-path: none;*/
  /*overflow: visible;*/
  /*height: fit-content;*/
  /*}*/
  background-image: url("../../static/navbar/test_navbar.svg");
  background-size: cover;
  background-position: center center;
}
.language-selector {
  position: absolute;
  right: 0;
  color: #000;
  font-size: 27px;
  top: 0;
  text-decoration: underline;
  @media screen and (max-width: 450px) {
    display: none;
  }
}

.logo {
  position: absolute;
  top: 20px;
  left: 2%;
  width: 200px;
  @media screen and (max-width: 450px) {
    width: 150px;
    left: 0;
  }
}
.links {
  float: right;
  align-items: center;
  @media screen and (max-width: 450px) {
    display: none;
  }
}
.navbar-btn {
  display: inline-block;
  text-decoration: none;
  font-family: Roboto;
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 0.5px;
  color: #000;
  transition: all 0.2s;
  &:hover {
    transform: translateY(-3px) scale(1.15);
  }
  &:active {
    transform: translateY(-1px);
  }
}
.navbar-divider {
  width: 1px;
  height: 22px;
  background-color: #000;
}
.move-right {
  transform: translateX(2px);
}
.move-left {
  transform: translateX(-2px);
}
.navbar-btn-link {
  height: 52px;
  border-radius: 30px;
  background-color: #e6378f;
  padding: 15px 45px;
  box-shadow: 0 7px 20px 0 rgba(0, 0, 0, 0.19);
  font-family: Roboto;
  font-size: 20px;
  font-weight: bold;
  line-height: 22px;
  letter-spacing: 0.4px;
  color: #fff;
  text-decoration: none;
  transition: all 0.2s;
  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 14px 20px 0 rgba(0, 0, 0, 0.19);
  }
  &:active {
    transform: translateY(-1px);
    box-shadow: 0 7px 20px 0 rgba(0, 0, 0, 0.19);
  }
}
/*.logo:hover {*/
/*animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;*/
/*transform: translate3d(0, 0, 0);*/
/*backface-visibility: hidden;*/
/*perspective: 1000px;*/
/*}*/

/*@keyframes shake {
    10%, 90% {
      <!--transform: translate3d(-1px, 0, 0);-->
    }

    20%, 80% {
      transform: translate3d(2px, 0, 0);
    }

    30%, 50%, 70% {
      <!--transform: translate3d(-4px, 0, 0);-->
    }

    40%, 60% {
      transform: translate3d(4px, 0, 0);
    }
  }*/
</style>
