<template>
  <div>
    <app-header></app-header>
    <qualities></qualities>
    <story-behind></story-behind>
    <!--<partners></partners>-->
  </div>
</template>

<script>
import AppHeader from "./AppHeader";
import Qualities from "./Qualities";
import StoryBehind from "./StoryBehind";
import Partners from "./Partners";

export default {
  name: "HomePage",
  metaInfo: {
    title:
      localStorage.lang === "ar"
        ? "ايجو | تطبيق ايجو لتوصيل المشاوير بطريقة آمنه وأسعار مناسبه"
        : "Ego | Ego app to deliver rids in a safe and affordable way",
  },
  meta: [],
  components: {
    AppHeader,
    Qualities,
    StoryBehind,
    Partners,
  },
};
</script>

<style scoped></style>
