<template>
  <div>
    <v-container
      v-show="!registrationSuccessful"
      :class="{ rtl: lang === 'ar' }"
      class="steps"
    >
      <v-layout class="header">
        <div class="header-line"></div>
        <h1 class="header-title">
          {{ $t("create account in 1 step") }}
        </h1>
        <div class="header-line"></div>
      </v-layout>
      <v-layout>
        <v-form
          style="display: flex; flex-wrap: wrap; width: 100%"
          v-model="validForm1"
          ref="form1"
          lazy-validation
        >
          <v-flex md1 v-if="lang === 'ar'"></v-flex>
          <v-flex md5 xs12 offset-md1>
            <v-text-field
              :rules="[rules.required, rules.letters]"
              :label="$t('first name')"
              box
              v-model="firstName"
              required
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md5>
            <v-text-field
              :rules="[rules.required, rules.letters]"
              box
              :label="$t('last name')"
              v-model="lastName"
              required
            ></v-text-field>
          </v-flex>

          <v-flex md1 v-if="lang === 'ar'"></v-flex>
          <v-flex md5 xs12 offset-md1 style="position: relative">
            <v-text-field
              ref="password"
              :type="show1 ? 'text' : 'password'"
              :rules="[
                rules.required,
                value => value.length > 5 || $t('passwordLetters')
              ]"
              box
              :label="$t('password')"
              v-model="password"
              required
            ></v-text-field>
            <v-icon
              class="icon-1"
              :class="{ 'icon-rtl': lang === 'ar' }"
              @click="show1 = !show1"
            >
              {{ show1 ? "visibility_off" : "visibility" }}
            </v-icon>
          </v-flex>
          <v-flex md5 xs12 style="position: relative">
            <v-text-field
              :rules="[rules.required, rules.email]"
              box
              :label="$t('email')"
              v-model="email"
              required
            ></v-text-field>
          </v-flex>

          <v-flex md1 v-if="lang === 'ar'"></v-flex>
          <v-flex md5 xs12 offset-md1>
            <v-text-field
              box
              :label="$t('referralCode')"
              v-model="referredBy"
            ></v-text-field>
          </v-flex>
          <v-flex md5 xs12> </v-flex>

          <v-flex xs12 offset-md1>
            <v-checkbox
              :label="$t('agreeTerms')"
              v-model="agreeTerms"
              required
              :rules="[rules.required]"
            ></v-checkbox>
          </v-flex>
        </v-form>
      </v-layout>
      <button
        class="next"
        id="ego_client_submit_registration_btn"
        @click="submit1"
        style="margin: 12px auto"
      >
        {{ $t("become a client") }}
      </button>
      <v-snackbar :timeout="3000" v-model="requestErrorPresent" color="red">
        {{ requestErrorMessage }}
        <v-btn flat color="black" @click.native="requestErrorPresent = false"
          >Close</v-btn
        >
      </v-snackbar>
    </v-container>
    <v-container
      v-show="registrationSuccessful"
      :class="{ rtl: lang === 'ar' }"
      class="steps"
    >
      <v-layout column align-center>
        <p class="text mb-4 font-weight-bold" style="font-size: 35px">
          {{ $t("confirmRegistration") }}
        </p>
        <p class="text" style="font-size: 25px">
          {{ $t("pleaseDownloadApp") }}
        </p>
        <p class="text" style="font-size: 25px">
          {{ $t("enterCode") }}
        </p>
        <p class="text" style="font-size: 25px; color: #e6378f">
          {{ $t("campaignCode") }}
        </p>
        <p class="text mt-3" style="font-size: 25px">
          {{ $t("getFirstRide") }}
        </p>
        <p class="text mb-5 mt-3" style="font-size: 25px; color: red">
          {{ $t("campaignCanceled") }}
        </p>
        <div class="mt-0">
          <h1 class="title-2 mb-2=" style="text-align: center">
            {{ $t("downloadClientApp") }}
          </h1>
          <v-layout wrap>
            <v-flex
              xs12
              md3
              :style="{
                'text-align': 'center',
                width: '100%',
                'margin-left': lang === 'en' ? 'auto' : 0,
                'margin-right': lang === 'ar' ? 'auto' : 0
              }"
            >
              <a :href="androidAppUrl" target="_blank" class="android-driver">
                <img
                  v-if="lang === 'ar'"
                  style="width: 100%"
                  src="../../static/become-driver/google-play-badge.png"
                  alt=""
                />
                <img
                  v-if="lang === 'en'"
                  style="width: 100%"
                  src="../../static/become-driver/google-play-badge (en).png"
                  alt=""
                />
              </a>
            </v-flex>
            <v-flex
              xs12
              md3
              :style="{
                'text-align': 'center',
                width: '100%',
                'margin-right': lang === 'en' ? 'auto' : 0,
                'margin-left': lang === 'ar' ? 'auto' : 0
              }"
            >
              <a :href="iosAppUrl" target="_blank" class="ios-driver">
                <img
                  v-if="lang === 'ar'"
                  style="width: 100%"
                  src="../../static/become-driver/app-store-badge-ar.png"
                  alt=""
                />
                <img
                  v-if="lang === 'en'"
                  style="width: 100%"
                  src="../../static/become-driver/app-store-badge-en.png"
                  alt=""
                />
              </a>
            </v-flex>
          </v-layout>
        </div>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import backend from "../backend";
import config from "../config";
import HTTP from "../http-common";
import storage from "../storage";

export default {
  metaInfo: {
    title: "Ego | Client Registration"
  },
  data() {
    return {
      show1: false,
      registrationSuccessful: false,
      agreeTerms: false,
      validForm1: false,
      email: "",
      dialCode: localStorage.dialCode,
      firstName: "",
      referredBy: storage.referredBy(),
      language: this.lang,
      lastName: "",
      password: "",
      phone: "",
      rules: {
        required: value => !!value || this.$t("required"),
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || this.$t("invalidEmail");
        },
        letters: value => {
          const pattern = /^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z-_]*$/;
          return pattern.test(value) || this.$t("invalidName");
        }
      },

      requestErrorPresent: false,
      requestErrorMessage: "",

      androidAppUrl: config.androidAppUrl("client"),
      iosAppUrl: config.iosAppUrl("client")
    };
  },
  computed: {
    lang() {
      return localStorage.lang || this.$route.path.substring(1, 3);
    }
  },
  methods: {
    handleRequestError(err) {
      this.requestErrorMessage = err.message;
      this.requestErrorPresent = true;
    },
    async submit1() {
      if (!this.$refs.form1.validate()) {
        return;
      }

      const {
        lang,

        firstName,
        lastName,
        email,
        password,

        referredBy,
        dialCode
      } = this;

      try {
        const client = await backend.registerClient(lang, {
          phone: localStorage.phone,

          firstName,
          lastName,
          email,
          password,

          referredBy,
          dialCode
        });

        //mixpanel integration
        mixpanel.identify(client.id);
        mixpanel.track("Registration Complete", {
          $first_name: client.firstName,
          $last_name: client.lastName,
          $email: client.email,
          "Referral Code": client.referredBy
        });

        mixpanel.people.set({
          $first_name: client.firstName
        });
        mixpanel.people.set({
          $last_name: client.lastName
        });
        mixpanel.people.set({ $email: client.email });
        mixpanel.people.set({
          "Referral Code": client.referredBy
        });
        mixpanel.people.set({
          "User ID": client.id
        });
        mixpanel.reset();

        this.registrationSuccessful = true;
        this.$confetti.start();
        setTimeout(() => {
          this.$confetti.stop();
        }, 3000);
      } catch (err) {
        this.handleRequestError(err);
      }
    }
  },
  async created() {
    const accessToken = localStorage.accessToken;

    if (!accessToken) {
      this.$router.push(`/${this.lang}/client-register`);
      return;
    }

    HTTP.defaults.headers["X-Access-Token"] = accessToken;
  },
  mounted() {
    this.$vuetify.goTo(257, { easing: "easeInOutCubic", duration: 2000 });
  }
};
</script>

<style lang="scss" scoped>
.steps {
  padding-top: 210px;
  &.rtl {
    direction: rtl;
  }
}

.header {
  padding-top: 50px;
  padding-bottom: 60px;
  flex-direction: column;
  align-items: center;
  .header-line {
    width: 129px;
    height: 5px;
    background-color: #f18642;
    margin: 10px 0;
  }
  .header-title {
    width: 530px;
    font-family: Roboto;
    font-size: 42px;
    font-weight: 300;
    letter-spacing: 0.9px;
    text-align: center;
    color: #4a4a4a;
    @media screen and (max-width: 450px) {
      width: 200px;
      font-size: 30px;
    }
  }
}

.step-title {
  justify-content: center;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.3px;
  text-align: center;
  color: #4a4a4a;
}

.next {
  box-sizing: border-box;
  height: 55px;
  width: 348px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  background-color: #00ace6;
  font-family: Roboto;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 2.9px;
  color: #ffffff;
  transition: all 0.2s;
  &:hover {
    border: 6px solid rgb(207, 239, 250);
  }
  @media screen and (max-width: 450px) {
    width: 100%;
  }
}

.step-head {
  display: flex;
  justify-content: center;
  align-items: center;
  .guide {
    border-radius: 50%;
    width: 27px;
    height: 27px;
    background-color: #c2cad2;
    transition: all 0.3s;
    transition-delay: 0.7s;
  }
}

.step-head.step-head-active {
  .guide {
    border-radius: 50%;
    width: 45px;
    height: 45px;
    background-color: #fff;
    box-shadow: 0 0 20px 0 lightgray;
    border: 10px solid #00ace6;
  }
}

.step-line {
  display: flex;
  align-items: center;
  .line {
    height: 3px;
    background-color: #c2cad2;
    flex: 1;
    transition: background-color 0.3s;
    transition-delay: 0.7s;
  }
}

.step-line.step-line-active {
  .line {
    background-color: #00ace6;
  }
}

.slide2-enter-active {
  animation: slide-reverse-2 0.5s;
}

.slide2-leave-active {
  animation: slide-2 0.5s;
}

@keyframes slide-2 {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(500px);
    opacity: 0;
  }
}

@keyframes slide-reverse-2 {
  100% {
    transform: translateX(0);
    opacity: 1;
  }
  0% {
    transform: translateX(-500px);
    opacity: 0;
  }
}

@media screen and (max-width: 450px) {
  @keyframes slide-2 {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @keyframes slide-reverse-2 {
    100% {
      opacity: 1;
    }
    0% {
      opacity: 0;
    }
  }
}

.at-input,
.at-select {
  margin: 25px 30px;
}

.image-holder-1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    @media screen and (max-width: 450px) {
      margin: 0 20px;
    }
  }
}

.image-holder-2 {
  display: flex;
  align-items: center;
  border-radius: 12px;
  background-color: #eff7ff;
  border: dashed 2px #00ace6;
  font-family: Roboto;
  font-size: 20px;
  color: #00ace6;
  margin-bottom: 20px;
  @media screen and (max-width: 450px) {
    font-size: 16px;
  }
  .upload-icon {
    padding: 20px;
    font-size: 50px;
    color: #00ace6;
    cursor: pointer;
    @media screen and (max-width: 450px) {
      font-size: 30px;
      padding: 20px 5px;
    }
  }
}

.image-title {
  opacity: 0.51;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.55;
  letter-spacing: 0.2px;
  text-align: center;
  color: #4a4a4a;
  margin-top: 10px;
}

.text {
  font-family: Roboto;
  font-size: 42px;
  font-weight: 300;
  letter-spacing: 0.9px;
  text-align: center;
  color: #4a4a4a;
}
.icon-1 {
  position: absolute;
  top: 23%;
  left: 80%;
  cursor: pointer;
  &.icon-rtl {
    left: 20%;
  }
}

.social-media-btn {
  /*display: flex;*/
  /*height: 52px;*/
  text-decoration: none;
  width: 90%;
  max-width: 220px;
  position: relative;
  border-radius: 6px;
  background-color: #ffffff;
  padding: 8px 35px 8px 50px;
  box-shadow: 0 7px 20px 0 rgba(0, 0, 0, 0.19);
  font-family: Roboto;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.4px;
  color: #000;
  transition: all 0.2s;
  @media screen and (max-width: 450px) {
    width: 90%;
    font-size: 15px;
    margin-top: 10px;
  }
  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 14px 20px 0 rgba(0, 0, 0, 0.19);
  }
  &:active {
    transform: translateY(-1px);
    box-shadow: 0 7px 20px 0 rgba(0, 0, 0, 0.19);
  }
  .icon {
    width: 30px;
    position: absolute;
    left: 15px;
    top: 18px;
    @media screen and (max-width: 450px) {
      top: 12px;
    }
  }
  .btn-content {
    display: flex;
    flex-direction: column;
    text-align: left;
    .btn-top {
      font-size: 13px;
    }
  }
}
</style>
