<template>
  <div>
    <v-container fluid class="pinContainer2" :class="{rtl:lang == 'ar'}" v-scroll="onScroll" id="why-ego">
      <span class="small-title hidden-sm-and-up">{{$t('why ego')}}</span>
      <v-container fluid grid-list-xs style="height: 100vh" >
        <v-layout
          wrap
          v-for="(p, index) in panels"
          class="panel"
          :class="`panel2-${index}`"
          :key="index"
          :ref="`panel2${index}`"
        >
          <v-flex md6 xs12 class="left-container">
            <h2 class="title">{{p[lang].title}}</h2>
            <p class="text">{{p[lang].text}}</p>
          </v-flex>
          <!--<v-spacer></v-spacer>-->
          <v-flex md6 xs12 class="right-container">
            <img class="icon" :src="p.icon" alt="">
          </v-flex>
          <!--<v-flex md1></v-flex>-->
        </v-layout>
        <div  class="guide hidden-sm-and-down">
          <span class="guide-title">{{$t('why ego')}}</span>
          <div class="guide-line"></div>
          <div class="fixed"></div>
          <div class="inner-guide inner-guide-1">
            <div class="inner-guide-control" :style="{width: `${point1}px`, height: `${point1}px`}"></div>
          </div>
          <div class="inner-guide inner-guide-2">
            <div class="inner-guide-control" :style="{width: `${point2}px`, height: `${point2}px`}"></div>
          </div>
          <div class="inner-guide inner-guide-3">
            <div class="inner-guide-control" :style="{width: `${point3}px`, height: `${point3}px`}"></div>
          </div>
          <!--<div class="inner-guide inner-guide-4">-->
            <!--<div class="inner-guide-control" :style="{width: `${point4}px`, height: `${point4}px`}"></div>-->
          <!--</div>-->
          <div class="fixed"></div>
        </div>
        <div  class="guide guide-small hidden-sm-and-up">
          <div class="guide-line"></div>
          <div class="fixed"></div>
          <div class="inner-guide inner-guide-1">
            <div class="inner-guide-control" :style="{width: `${point1}px`, height: `${point1}px`}"></div>
          </div>
          <div class="inner-guide inner-guide-2">
            <div class="inner-guide-control" :style="{width: `${point2}px`, height: `${point2}px`}"></div>
          </div>
          <div class="inner-guide inner-guide-3">
            <div class="inner-guide-control" :style="{width: `${point3}px`, height: `${point3}px`}"></div>
          </div>
          <!--<div class="inner-guide inner-guide-4">-->
            <!--<div class="inner-guide-control" :style="{width: `${point4}px`, height: `${point4}px`}"></div>-->
          <!--</div>-->
          <div class="fixed"></div>
        </div>
      </v-container>
    </v-container>
  </div>
</template>

<script>
  export default {
    name: 'Qualities',
    mounted() {
      this.$nextTick(this.pinContainerScene2);
    },
    data() {
      return {
        point1: 40,
        point2: 0,
        point3: 0,
        // point4: 0,
        panels: [
          {
            icon: '../../../static/home-page/story-behind-1.svg',
            // image: '../../../static/home-page/mobile-panel-1.svg',
            en: {
              text: 'We provide you with affordable everyday rides that are very luxurious. Whether it be our high-end cars or premium ones, we always ensure a luxurious experience.',
              title: 'Affordable Luxury',
            },
            ar: {
              text: 'نقدم لكم رحلات يومية بأسعار معقولة وعالية الفخامة. سواء كانت مركباتنا المميزة أو الفخمة, سنمنحكم ونضمن لكم تجربة عصرية.',
              title: 'فخامة بأسعار معقولة',
            },
          },
          {
            icon: '../../../static/home-page/story-behind-3.svg',
            // image: '../../../static/home-page/mobile-panel-3.svg',
            en: {
              text: 'Your ride to the airport is no longer painful. Ego\'s skilled drivers will help you with the luggage. You can request help with your luggage when ordering your next ride.',
              title: 'We got your luggage',
            },
            ar: {
              text: 'رحلتكم الى المطار ليست مرهقة بعد اﻵن. سائقون إيجو المحترفون على أكمل الاستعداد لمساعدتكم في الحقائب. يمكنكم طلب المساعدة في الحقائب عند القيام بطلب رحلتكم القادمة.',
              title: 'حقائبكم مسئوليتنا',
            },
          },
          {
            icon: '../../../static/home-page/story-behind-4.svg',
            // image: '../../../static/home-page/mobile-panel-3.svg',
            en: {
              text: 'You can choose everything from the model of the car to other exciting ride options. We attentively listen to all your feedback and work on new features to make your rides more comfortable.',
              title: 'You dream, we make it possible',
            },
            ar: {
              text: ' لديك حرية الاختيار, بدءا من نوع المركبة إلى الإضافات الاختيارية في الرحلة. ننصت إلى جميع أرائكم واقتراحاتكم ونسعى إلى مزايا أكثر لجعل رحلتكم أكثر راحة.',
              title: 'قم بالحلم, و سنجعله حقيقة.',
            },
          },
        ],
      };
    },
    methods: {
      onScroll() {
        this.point1 = this.$refs.panel20[0].style.opacity * 40;
        this.point2 = this.$refs.panel21[0].style.opacity * 40;
        this.point3 = this.$refs.panel22[0].style.opacity * 40;
        // this.point4 = this.$refs.panel23[0].style.opacity * 40;
      },
      pinContainerScene2() {
        const Length = this.panels.length;

        // Create a new Timeline (equivalent to new TimelineMax())
        const tl = new this.$gsap.TimelineMax();

        for (let i = 0; i < Length; i += 1) {
          if (i === 0) {
            tl.to(`.panel2-${i}`, 1.5, { opacity: 0, ease: this.$gsap.Linear.easeNone });
          } else if (i === 2) {
            tl.fromTo(`.panel2-${i}`, 0.75, { opacity: 0 }, { opacity: 1, ease: this.$gsap.Linear.easeNone });
          } else {
            tl.fromTo(`.panel2-${i}`, 0.75, { opacity: 0 }, { opacity: 1, ease: this.$gsap.Linear.easeNone });
            tl.to(`.panel2-${i}`, 0.75, { opacity: 0, ease: this.$gsap.Linear.easeNone });
          }
        }

        // create scene and set its params
        const scene = new this.$scrollmagic.Scene({
          triggerElement: '.pinContainer2',
          triggerHook: 0,
          duration: 2180,
        })
          .setPin('.pinContainer2')
          .setTween(tl);
        // Add scene to ScrollMagic controller by emiting
        // an 'addScene' event on vm.$ksvuescr (which is our global event bus)
        this.$ksvuescr.$emit('addScene', 'pinContainerScene2', scene);
      },
    },
    computed: {
      lang() {
        return this.$route.path.substring(1, 3) || localStorage.lang;
      },
    },
    destroyed() {
      // Destroy ScrollMagic when our component is removed from DOM
      // this.$ksvuescr.$emit('destroy');
    },

  };
</script>

<style lang="scss" scoped>
  .pinContainer2 {
    overflow: hidden;
    position: relative;
    padding-top: 320px;
    margin-bottom: 100px;
    height: 100vh;
    clip-path:
      polygon(100.0000% 0.0367%, 100.0000% 95.3689%, 95.0236% 95.3689%, 89.5828% 98.7606%, 85.1539% 97.8150%, 81.0962% 93.3403%, 72.4614% 96.7739%, 70.5308% 93.3403%, 65.8517% 96.7739%, 57.0924% 93.3403%, 50.0000% 97.8150%, 42.3585% 97.8150%, 33.4595% 100.2029%, 25.4901% 98.7606%, 9.8374% 98.7606%, 5.2797% 95.3689%, 0.0000% 95.3689%, 0.0000% 28.7301%, 100.0000% 0.0366%);
    background-color: #f0f9fc;
    &.rtl{
      direction: rtl;
    }
    @media screen and (max-width:450px) {
      padding-top: 0;
      margin-bottom: 0;
      clip-path: none;
    }
  }
  .panel {
    height: 100vh;
    position: absolute;
    top: 45%;
    width: 100%;
    @media screen and (max-width:450px) {
      top: 15%;
      left: 0;
      margin: 0 20px !important;
      width: 90%;
    }
  }
  .right-container{
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width:450px) {
    }
    .icon{
      margin-top: -550px;
      transform: translateY(-30%);
      @media screen and (min-width: 451px) and (max-width: 1600px){
        margin-top: -250px;
        width: 50%;
      }
      @media screen and (max-width:450px) {
        width: 35vh;
        margin-top: -375px;
        transform: none;

      }
    }
  }
  .left-container{
    display: flex;
    flex-direction: column;
    align-items: center;

    .title{
        text-align: center;
        font-family: Roboto;
        font-size: 35px!important;
        font-weight: bold;
        letter-spacing: 0.6px;
        color: #00b3e9;
      @media screen and (max-width: 450px) {
        font-size: 25px!important;
      }
    }
    .text {
      width: 80%;
      margin-top: 30px;
      font-family: Roboto;
      font-size: 25px;
      line-height: 1.27;
      text-align: center;
      color: #9b9b9b;
      @media screen and (max-width: 450px) {
        font-size: 15px;
        width: 100%;
      }
    }
  }
  .guide{
    position: absolute;
    display: flex;
    flex-direction: column;
    height: 60%;
    justify-content: space-around;
    left: 50%;
    transform: translateX(-50%);
    align-items: center;
    top: 25%;
    .guide-line{
      width: 3px;
      background-color: #00ace6;
      position: absolute;
      z-index: 0;
      height: 65%;
      top: 27%;
    }
    .guide-title{
      opacity: 0.5;
      font-family: Roboto;
      font-size: 51px;
      font-weight: 900;
      letter-spacing: 1.1px;
      color: #0fb4e7;
    }
    .inner-guide{
      background-color: #fff;
      height: 45px;
      width: 45px;
      border-radius: 50%;
      border: 3px solid #00b2e9;
      position: relative;
      @media screen and (max-width: 450px) {
        height: 25px;
        width: 25px;
      }

    }
    .inner-guide-control{
      border-radius: 50%;
      background-color: #0fb4e7;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-49%) translateY(-49%);
    }
    .fixed{
      width: 27px;
      height: 27px;
      background-color: #15c7ff;
      border-radius: 50%;
      z-index: 1;
      @media screen and (max-width: 450px) {
        height: 15px;
        width: 15px;
      }
    }
  }

  .guide.guide-small{
    flex-direction: column;
    left: 95%;
    width: 90%;
    position: absolute;
    display: flex;
    height: 60%;
    justify-content: space-around;
    transform: translateX(-50%);
    align-items: center;
    top: 34%;
    .guide-line{
      width: 3px;
      background-color: #00ace6;
      position: absolute;
      z-index: 0;
      height: 80%;
      top: 9%;
      right: 50%;
      transform: translateX(50%);
    }

    .inner-guide{
      background-color: #fff;
      height: 45px;
      width: 45px;
      border-radius: 50%;
      border: 3px solid #00b2e9;
      position: relative;
      @media screen and (max-width: 450px) {
        height: 25px;
        width: 25px;
      }

    }
    .inner-guide-control{
      border-radius: 50%;
      background-color: #0fb4e7;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-49%) translateY(-49%);
    }
  }

  .small-title{
    text-align: center;
    font-family: Roboto;
    font-size: 30px!important;
    font-weight: bold;
    letter-spacing: 0.6px;
    color: #00b3e9;
    display: block;
    margin-top: 20px;
  }
</style>
